import React, { useRef, useState, useEffect, RefObject } from 'react';
import styled from 'styled-components'
import WebFont from 'webfontloader';
import { colours }  from '../styles/Themes';

type Pos = {
  x: number;
  y: number;
  scale?: number;
}

type Props = {
  backgroundUrl: string;
  withFont?: boolean;
  imageDetails: {
    url: string;
    pos: Pos;
  };
  storeImage: (value: string) => void;
  text?: {
    value: string;
    pos: Pos;
  };
}

const Canvas = styled.canvas`
  display: none;
`

const generateImageFromCanvas = (canvas: HTMLCanvasElement): string =>
  encodeURI(canvas.toDataURL('image/jpeg', 0.6))


const loadImage = (
  url: string,
  setState: (value: HTMLImageElement) => void,
): Promise<{ img: HTMLImageElement, setState: (value: HTMLImageElement) => void }> => {
  return new Promise(resolve => {
    const img = new Image()
    img.onload = () => {
      setState(img)
      resolve()
    }
    img.setAttribute('crossorigin', 'anonymous');
    img.src = url
  })
}

export const loadImages = async (images: {
  path: string;
  setState: (value: HTMLImageElement) => void;
}[]) => {
  return Promise.all(
    images.map(({ path, setState }) => loadImage(path, setState)),
  )
}

const drawCanvas = (
  canvasRef: RefObject<HTMLCanvasElement>,
  background: HTMLImageElement,
  image: HTMLImageElement,
  imagePos: Pos,
  storeImage: (value: string) => void,
  text?: {
    value: string
    pos: Pos
  },
) => {
  if(canvasRef && canvasRef.current) {
    const ctx = canvasRef.current.getContext('2d');
    if(ctx !== null) {
      const { width, height } = background;
      ctx.canvas.width = width;
      ctx.canvas.height = height;
      ctx.drawImage(background, 0, 0);
      const imgScale = imagePos.scale ? imagePos.scale : 1;
      ctx.drawImage(image, imagePos.x, imagePos.y, image.width * imgScale, image.height * imgScale);
      if(text) {
        const fontSize = text.value.length > 21 ? '22px' : '40px'
        ctx.font = `${fontSize} 'Knockout 92 A', 'Knockout 92 B'`;
        ctx.textAlign = 'center';
        ctx.fillStyle = colours.darkGrey;
        ctx.fillText(text.value, text.pos.x, text.pos.y)
      }
      const img = generateImageFromCanvas(canvasRef.current)
      storeImage(img)
    }
  }
}

const ImageGenerator = ({ backgroundUrl, imageDetails, text, storeImage, withFont }: Props) => {
  const [loading, setLoading] = useState(true);
  const [background, setBackground] = useState<HTMLImageElement | null>(null);
  const [image, setImage] = useState<HTMLImageElement | null>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  useEffect(() => {
    loadImages([
        {
          path: backgroundUrl,
          setState: setBackground,
        },
        {
          path: imageDetails.url,
          setState: setImage,
        },
      ])
      .then(() => {
        setLoading(false);
      })
      .catch(err => {
        setLoading(false)
      })
  }, [backgroundUrl, imageDetails.url])

  useEffect(() => {
    if(background && image && canvasRef.current) {
      if(withFont){
        WebFont.load({
          custom: {
            families: ['Knockout 92 A', 'Knockout 92 B'],
            urls: ['https://cloud.typography.com/7274418/6094012/css/fonts.css'],
          },
          active: function() {
            drawCanvas(canvasRef, background, image, imageDetails.pos, storeImage, text);
          },
        })
      } else {
        drawCanvas(canvasRef, background, image, imageDetails.pos, storeImage, text);
      }
    }
  }, [loading, background, image, imageDetails.pos, storeImage, text, withFont])
  return !loading ? <Canvas id={`${Date.now()}`} ref={canvasRef}/> : null
}


export default ImageGenerator