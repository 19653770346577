import React from "react";

import Icon, { IconSize } from "./Icon";

type Props = {
  size: IconSize
}

const LockIcon = ({ size, ...rest }: Props) => (
  <Icon {...rest} size={size}>
    <svg viewBox="0 0 96 99" xmlns="http://www.w3.org/2000/svg">
      <path d="M48 0c23.196 0 42 18.512 42 41.348 0 3.033-.012 5.922-.035 8.67L96 50v49H0V50l6.035.018C6.012 47.27 6 44.38 6 41.348 6 18.512 24.804 0 48 0zm2.173 83.176H42.59V91h7.584v-7.824zm-2.448-25.008c-6.864 0-11.232 3.792-11.232 10.32v2.304h8.544v-1.488c0-2.448.768-3.312 2.208-3.312 1.584 0 1.968.96 1.968 2.256 0 3.888-6.528 4.416-6.384 12.48h7.2c-.24-5.424 8.976-5.808 8.976-13.44 0-5.088-3.696-9.12-11.28-9.12zM48 14c-15.464 0-28 11.043-28 24.665 0 4.027.015 7.806.044 11.35l55.896-.014c.04-3.504.06-7.277.06-11.336C76 25.043 63.464 14 48 14z" fill="#000" fillRule="evenodd"/>
    </svg>
  </Icon>
);

export default LockIcon;