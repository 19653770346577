import styled from 'styled-components';
import { space, media } from '../../styles/Layout';
import { fontFamily, fontSize } from '../../styles/Typography';
import { colours, StoryTheme } from '../../styles/Themes';

export const Container = styled.div<{ lightTheme?: boolean; slideCount:boolean; themed?: boolean }>`
  position: ${({ themed }) => !themed ? 'relative' : 'static' };
  padding-top: ${({ slideCount }) => slideCount ? 0 : space(1) }};
  padding-bottom: ${({ slideCount }) => slideCount ? 0 : space(3) }};

  *:focus {
    outline: none;
  }

  /* Slider */
  .slick-slider {
    position: ${({ themed }) => !themed ? 'relative' : 'static' };
    display: block;
    box-sizing: border-box;
    user-select: none;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
    margin-bottom: ${space(2)};

    ${({ lightTheme }) => !lightTheme ? 
      `
        ${media.medium} {
          padding-left: 80px;
          padding-right: 80px;
          max-width: 928px;
          overflow: hidden;
          margin: 0 auto;
        }
      `
      : null
    }
  }
  .slick-list {
      position: relative;
      display: block;
      overflow: hidden;
      margin: 0;
      padding: 0;
      
      ${({ lightTheme }) => !lightTheme ? 
      `
        ${media.medium} {
          overflow: visible;
        }
      `
      : null
    }
  }
  .slick-list:focus {
    outline: none;
  }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand;
  }
  .slick-slider .slick-track,
  .slick-slider .slick-list {
    transform: translate3d(0, 0, 0);
  }

  .slick-track {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: stretch;
  }
  .slick-track:before,
  .slick-track:after {
      display: table;
      content: '';
  }
  .slick-track:after {
      clear: both;
  }
  .slick-loading .slick-track {
      visibility: hidden;
  }
  .slick-slide {
      display: none;
      float: left;
      height: auto;
      min-height: 1px;
      & > * {
        height: 100%;
      } 
  }
  .slick-slide *:focus {
    outline: none;
  }
  [dir='rtl'] .slick-slide {
    float: right;
  }
  .slick-slide img {
      display: block;
  }
  .slick-slide.slick-loading img {
    display: none;
  }
  .slick-slide.dragging img {
    pointer-events: none;
  }
  .slick-initialized .slick-slide {
      display: block;
  }
  .slick-loading .slick-slide  {
    visibility: hidden;
  }
`

export const SlideCount = styled.div`
  font-family: ${fontFamily.juniorFeatherWeight};
  font-size: ${fontSize.medium};
  color: ${colours.darkGrey};
  letter-spacing: 0.13px;
  margin-bottom: ${space(2)};
  display: block;
  text-align: center;
`

export const Markers = styled.ul`
  margin: 0 auto;
  display: flex;
  width: 100%;
  padding: ${space(2)} ${space(1)} 0;
  justify-content: center;
  li {
    margin-right: ${space(0.5)}
  }
`

export const Marker = styled.button<{ themed?: boolean }>`
  background: ${({ themed }) => themed ? 'rgba(255, 255, 255, 0.35)' : 'rgba(0, 0, 0, 0.2)' };
  height: 4px;
  width: 16px;
  border: none;
  padding: 0;
  border-radius: 2px;
  cursor: pointer;
  line-height: normal;
  display: block;
  transition: background 0.3s linear;
  span {
    display: none;
  }
  .slick-active & {
    background: rgba(0, 0, 0, 1);
    background: ${({ themed }) => themed ? 'rgba(255, 255, 255, 1)' : 'rgba(0, 0, 0, 1)' };
  }

  &:hover {
    background: rgba(0, 0, 0, 1);
    background: ${({ themed }) => themed ? 'rgba(255, 255, 255, 1)' : 'rgba(0, 0, 0, 1)' };
  }

  &:focus {
    outline: none;
  }
`

export const ButtonWrap = styled.div<{ isNext?: boolean, lightTheme?: boolean; themed?: boolean; theme: StoryTheme }>`
  width: ${({ lightTheme }) => lightTheme ? 'auto' : '88px'};

  &:before {
    content: '';
    position: absolute;
    top:0;
    left:0;
    right: 0;
    bottom:0;
    display: block;
    width: 100%;
    height: 100%;
    background: ${({ isNext, lightTheme, themed, theme }) => {
      if(lightTheme) return 'none'
      if(themed) return theme.gradient2
      return isNext ? 
        'linear-gradient(to right, rgba(255, 255, 255, 0) 0%, #FFFFFF 86%)' : 'linear-gradient(to left, rgba(255, 255, 255, 0) 0%, #FFFFFF 86%)'
    }};

    ${({ isNext, themed }) => {
      return themed
        ? `mask-image: ${isNext ? 'linear-gradient(to right, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 86%)' : 'linear-gradient(to left, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 86%)'};`
        : ''
    }}
  }

  display: none;
  align-content: center;
  justify-content: ${({ lightTheme, isNext }) => {
    if(lightTheme) {
      return isNext ? 'flex-end' : 'flex-start'
    }
    else {
      return 'center'
    }
  }};
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 10;
  ${({ isNext, lightTheme }) => {
    if(lightTheme) {
      return isNext ? 'right: -40px;' : 'left: -40px;'
    }
    return isNext ? 'right: 0;' : 'left: 0;'
  }}
  ${media.medium} {
    display: flex;
  }
`

export const SlideshowButton = styled.button<{ lightTheme? : boolean, isNext?: boolean; themed?: boolean; hide:boolean;  }>`
  ${({ hide }) => hide ? 'opacity: 0; pointer-events: none; ' : ''}
  transition: opacity 280ms linear;
  oapcity: 1;
  border: none;
  background: none;
  padding: 0;
  cursor: pointer;
  position: relative;
  z-index: 1;
  svg {
    filter: drop-shadow(0 1px 3px rgba(0, 0, 0, .5));
  }
  path {
    transition: fill 0.2s linear, stroke 0.2s linear, transform 0.2s linear;
    fill: ${({ lightTheme, themed }) => lightTheme || themed ? '#FFFFFF' : '#000000'};
    stroke: #FFFFFF;
    transform-origin: 50% 50%;
  }
  &:hover {
    path {
      transform: scale(0.9);
      stroke: #989898;
      fill: ${({ lightTheme, themed }) => lightTheme || themed ? '#989898' : '#000000'};
    }
  }
  &:focus {
    outline: none;
  }
`
