import React from "react";

import Icon, { IconSize } from "./Icon";

type Props = {
  size: IconSize
}

const PollsIcon = ({ size, ...rest }: Props) => (
  <Icon {...rest} size={size}>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <g fill="none" fillRule="evenodd"><rect width="24" height="24" fill="#000" rx="2"/><path fill="#FFF" d="M20 6v14h-4V6h4zm-6 5v9h-4v-9h4zM8 4v16H4V4h4z"/></g>
    </svg>
  </Icon>
);

export default PollsIcon;