import React from "react";

import AvatarContainer from "./AvatarContainer";

export default (
  <AvatarContainer>
    <path
      fill="inherit"
      d="M48 4c24.3 0 44 19.7 44 44S72.3 92 48 92 4 72.3 4 48 23.7 4 48 4zm0 4C25.909 8 8 25.909 8 48s17.909 40 40 40 40-17.909 40-40S70.091 8 48 8zm11.166 46.57C45.506 68.237 28.315 75 28.315 75l-3.256-3.258 5.834-5.836v-.78l-2.874-2.874.083-.09a7.588 7.588 0 011.198-1.036 8.221 8.221 0 012.656-1.225c1.619-.424 2.944-.418 3.96-.161l.214.06c.123.037.207.07.25.09l.63-.104L75 21.78l-.12.727c-.874 5.048-4.395 20.74-15.714 32.063zM74.225 21L36.483 58.755l-.191-.056a5.917 5.917 0 00-.107-.028c-1.192-.302-2.706-.308-4.508.164a9.32 9.32 0 00-3.01 1.388c-.923.648-1.532 1.302-1.843 1.757l.066.7 2.834 2.836-5.445 5.447L21 67.683l.203-.495c1.218-2.91 7.925-18.07 20.22-30.37C53.36 24.878 70.173 21.63 74.225 21z"
    />
  </AvatarContainer>
);
