import React from "react";

import AvatarContainer from "./AvatarContainer";

export default (
  <AvatarContainer>
    <path
      fill="inherit"
      d="M48 4c24.3 0 44 19.7 44 44S72.3 92 48 92 4 72.3 4 48 23.7 4 48 4zm0 4C25.909 8 8 25.909 8 48s17.909 40 40 40 40-17.909 40-40S70.091 8 48 8zM25.65 70.71l13.032-12.987a30.93 30.93 0 018.671 21.773l-.004.274c-7.85-.158-15.654-3.178-21.7-9.06l13.033-12.987L25.649 70.71zm44.977-45.37c12.497 12.453 12.497 32.644 0 45.097-6.074 6.053-13.984 9.164-21.944 9.333l.004-.243a32.252 32.252 0 00-8.7-22.363l-.362-.38 16.633-16.576c6.206 5.939 14.377 9.107 22.825 9.03l.507-.01-.032-1.328-.506.009c-7.919.068-15.58-2.836-21.477-8.29l-.374-.351 13.275-13.229-.943-.94-13.275 13.23a30.935 30.935 0 01-8.671-21.792l.008-.504-.643-.016c8.535-.277 17.16 2.83 23.675 9.323zM16 48.142h.377a31.144 31.144 0 0120.988 8.29l.374.351-13.025 12.979A31.722 31.722 0 0116 48.142h.377H16zM46.035 16.06l.226-.013a32.26 32.26 0 008.7 22.848l.354.373-16.633 16.575c-6.166-5.901-14.273-9.066-22.665-9.03a31.722 31.722 0 019.355-21.473c5.748-5.729 13.14-8.822 20.663-9.28l.226-.013-.226.013z"
    />
  </AvatarContainer>
);
