import React from "react";

import Icon, { IconSize } from "./Icon";

type Props = {
  size: IconSize
}

const CloseIcon = ({ size, ...rest }: Props) => (
  <Icon {...rest} size={size}>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44 44">
    <g fill="none" fillRule="evenodd"><path fill="#CCC" d="M34.728 9.272a2.25 2.25 0 010 3.182L25.183 22l9.545 9.547a2.25 2.25 0 11-3.182 3.182L22 25.18l-9.547 9.547a2.25 2.25 0 11-3.182-3.182l9.547-9.547-9.547-9.545a2.25 2.25 0 113.182-3.182l9.547 9.545 9.545-9.545a2.25 2.25 0 013.182 0z"/><path fill="#989898" d="M80.728 9.272a2.25 2.25 0 010 3.182L71.183 22l9.545 9.547a2.25 2.25 0 11-3.182 3.182L68 25.18l-9.547 9.547a2.25 2.25 0 11-3.182-3.182l9.547-9.547-9.547-9.545a2.25 2.25 0 113.182-3.182l9.547 9.545 9.545-9.545a2.25 2.25 0 013.182 0z"/></g>    </svg>
  </Icon>
);

export default CloseIcon;