import axios, { AxiosInstance } from "axios";

export class UserDataClient {
  client: AxiosInstance;

  constructor() {
    this.client = axios.create({
      baseURL: "https://user-data.nsm-apis.com/"
    });
  }

  async login({ shortCode, visitDate }: LoginArgs): Promise<LoginResponse> {
    let resp = await this.client.post("/postvisit/v1/login/", {
      short_code: shortCode,
      visit_date: visitDate
    });
    return resp.data;
  }

  async pollSummaries(): Promise<PollSummary[]> {
    let resp = await this.client.get("/postvisit/v1/poll-summaries/");
    return resp.data;
  }

  async profile({ privateId }: { privateId: string }): Promise<ProfileResponse | 'error'> {
    try {
      let resp = await this.client.get(`/postvisit/v1/profile/${privateId}`);
      return resp.data;
    } catch {
      return 'error'
    }
  }

  async updateNickname({privateId, nickname}: {privateId: string, nickname: string}): Promise<'success' | 'error'> {
    try {
      await this.client.post(`/postvisit/v1/profile/${privateId}/nickname/`, {nickname});
      return 'success'
    } catch {
      return 'error'
    }
  }

  async publicProfile({ publicId }: { publicId: string }): Promise<PublicProfileResponse | 'error'> {
    try {
      let resp = await this.client.get(`/postvisit/v1/public-profile/${publicId}`);
      return resp.data;
    } catch {
      return 'error'
    }
  }
}

type LoginArgs = {
  shortCode: string;
  visitDate: string;
};

type LoginResponse = {
  private_id: string | null;
};

export type PollSummary = {
  poll_id: string;
  option_a_count: number;
  option_b_count: number;
};

export type ProfilePollSummary = {
  poll_id: string;
  vote: "A" | "B" | null;
  group_option_a_count: number;
  group_option_b_count: number;
};

export type QuizResult = {
  quiz_id: string;
  correct: boolean;
};

export type GameOnProfile = {
  basketball_points: number[] | null;
  classic_catch_video_url: string | null;
  cycle_distance: number | null;
  horse_position: number | null;
  netball_points: number[] | null;
  pressure_cooker_points: number[] | null;
  reaction_time_score: number | null;
  rugby_league_points: number[] | null;
  rugby_union_points: number[] | null;
  soccer_points: number[] | null;
  surfing_time: number | null;
  you_make_the_rules_visited: boolean;
}

export type HeadInTheCrowdsPhoto = {
  base_crowd_image_name: string;
  people_in_photo_count: number;
  photo_url: string;
}

export type DayAtTheRacesProfile = {
  horse_image_url: string;
  horse_name: string;
  horse_position: number;
};

export type ProfileResponse = {
  public_id: string;
  private_id: string;
  token_id: string;
  group_id: string | null;
  nickname: string | null;
  avatar_url: string | null;
  polls: ProfilePollSummary[];
  quizzes: QuizResult[];
  game_on: GameOnProfile;
  head_in_the_crowds_photos: HeadInTheCrowdsPhoto[];
  day_at_the_races: DayAtTheRacesProfile | null;
};

export type PublicProfileResponse = {
  public_id: string;
  nickname: string | null;
  avatar_url: string | null;
  polls: ProfilePollSummary[];
  game_on: GameOnProfile;
  head_in_the_crowds_photos: HeadInTheCrowdsPhoto[];
  day_at_the_races: DayAtTheRacesProfile | null;
};

const TOTAL_SPECIAL_FEATURES = 14;
export function percentSpecialFeaturesCompleted(profile: ProfileResponse) {
  const game_on = profile.game_on;
  let count = 0;

  // Souvenirs
  if (profile.head_in_the_crowds_photos.length > 0) {
    count += 1;
  }

  if (profile.day_at_the_races !== null) {
    count +=1;
  }

  if (game_on.classic_catch_video_url !== null) {
    count += 1;
  }

  if (game_on.you_make_the_rules_visited) {
    count += 1;
  }

  // Game on games
  if (game_on.basketball_points !== null) {
    count += 1;
  }

  if (game_on.cycle_distance !== null) {
    count += 1;
  }

  if (game_on.horse_position !== null) {
    count += 1;
  }

  if (game_on.netball_points !== null) {
    count += 1;
  }

  if (game_on.pressure_cooker_points !== null) {
    count += 1;
  }

  if (game_on.reaction_time_score !== null) {
    count += 1;
  }

  if (game_on.rugby_league_points !== null) {
    count += 1;
  }

  if (game_on.rugby_union_points !== null) {
    count += 1;
  }

  if (game_on.soccer_points !== null) {
    count += 1;
  }

  if (game_on.surfing_time !== null) {
    count += 1;
  }

  return 100 / TOTAL_SPECIAL_FEATURES * count;
}
