import React, { FC, ReactNode } from 'react';
import styled from 'styled-components';
import { fontFamily, fontSize } from '../styles/Typography';
import { colours } from '../styles/Themes';


const Container = styled.div`
  width: 100%;
  height: 0;
  text-align: center;
  position: relative;
  z-index: 10;
`
const Heading = styled.h2`
  font-family: ${fontFamily.juniorWelterWeight};
  font-size: ${fontSize.medium};
  text-transform: uppercase;
  position: relative;
  display: inline-block;
  padding-top: 8px;
  padding-left: 50px;
  padding-right: 50px;
  text-align: center;
  margin-top:-50%;
  z-index: 1;
  &:before {
    content: '';
    background: ${colours.white};
    border: 1px solid #ebebeb;
    width: 100%;
    height: 40px;
    border: 
    display: block;
    left: 0;
    top:0;
    transform: rotate(-3deg);
    position: absolute;
    z-index: -1;
  }
`
type Props = {
  children: ReactNode;
}
const Count: FC<Props> = ({ children }) => <Container>
  <Heading>{children}</Heading></Container>

export default Count
