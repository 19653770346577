import React from "react";

import AvatarContainer from "./AvatarContainer";

export default (
  <AvatarContainer>
    <path
      fill="inherit"
      d="M48 4c24.3 0 44 19.7 44 44S72.3 92 48 92 4 72.3 4 48 23.7 4 48 4zm0 4C25.909 8 8 25.909 8 48s17.909 40 40 40 40-17.909 40-40S70.091 8 48 8zm17.414 8.562l.17.17c.189.196.438.467.737.801l.575 6.074-2.1 2.453a505.423 505.423 0 01-8.285 9.304c-5.412 5.94-4.77 14.341-4.26 17.613l.025.156-12.35 12.778-.098.088c-.135.11-.295.184-.466.217l-.13.016-19.11.17-.101.009a.748.748 0 00-.636.748c.004.38.286.691.649.737l.1.006 19.153-.172.198-.02c.523-.07.99-.286 1.366-.62l.137-.132 33.707-34.875.071-.085a.755.755 0 00-.084-.976.737.737 0 00-.967-.06l-.083.073-2.2 2.277-.056-.007-.057-.003c-1.26-.016-2.09-1.073-2.246-2.29l-.644-6.957-.434-4.607 1.541 1.806c3.142 3.705 7.206 8.609 9.444 11.32l1.664 2.017s1.015 1.448-.382 2.771l-1.234 1.11c-6.203 5.537-33.305 29.51-37.31 33.052a1.752 1.752 0 01-1 .432l-.16.007L26.783 72l-5.311-.007-.133.007C18.39 72 16 69.593 16 66.622a5.374 5.374 0 013.612-5.085l9.176-4.797c.088-.048 1.643-3.562 3.4-7.585l4.125-.56.098-.021a.75.75 0 00.538-.823.745.745 0 00-.734-.65l-.1.007-3.224.438 1.597-3.674 4.179-.567.099-.02a.75.75 0 00.538-.823.745.745 0 00-.735-.65l-.1.007-3.284.446 1.387-3.205 4.213-.57.1-.02a.75.75 0 00.537-.823.745.745 0 00-.734-.65l-.1.007-3.322.45.05-.12c.172-.485 2.188-5.946 5.307-6.73 1.665-.419 2.947.237 3.803.98.353.306.824.44 1.287.395l.35-.038c2.006-.236 6.052-1.077 7.917-4.054l.305-.493c1.937-3.167 3.907-7.058 4.637-8.529l.3-.607.192-.286c.584-.821 2.371-2.99 4-1.41zm1.745 9.85l.418 4.558c.12 1.54.972 2.996 2.398 3.572l.198.073L59.451 45.71l-.165-.27c-.507-.854-1.316-2.442-1.552-4.209-.323-2.43 1.554-5.827 1.845-6.336l.038-.064 7.542-8.418z"
    />
  </AvatarContainer>
);
