import React from "react";

import AvatarContainer from "./AvatarContainer";

export default (
  <AvatarContainer>
    <path
      fill="inherit"
      d="M48 4c24.3 0 44 19.7 44 44S72.3 92 48 92 4 72.3 4 48 23.7 4 48 4zm0 4C25.909 8 8 25.909 8 48s17.909 40 40 40 40-17.909 40-40S70.091 8 48 8zM24.961 64.63c.908-3.477 5.982-5.302 11.388-3.913 5.406 1.389 8.95 5.427 8.043 8.903-.907 3.477-5.981 5.301-11.387 3.913-5.406-1.389-8.95-5.427-8.044-8.904zm34.719-3.913c5.407-1.389 10.481.436 11.388 3.912.908 3.477-2.636 7.515-8.043 8.904-5.406 1.389-10.48-.436-11.387-3.913-.907-3.476 2.637-7.514 8.042-8.903zM43.21 62.96h9.61c-1.741 1.692-2.738 3.736-2.655 5.775h-4.3c.083-2.04-.914-4.083-2.655-5.775h9.61-9.61zm-25.398-3.98l10.91.553c-2.554.834-4.46 2.492-5.052 4.764-.294 1.126-.232 2.277.128 3.389l-5.986-2.08c-1.24-.384-1.684-1.037-1.727-1.91l-.004-.148v-2.851c0-.899.695-1.636 1.581-1.71l.15-.007zm60.406 0c.956 0 1.731.768 1.731 1.717v2.851c0 .948-.423 1.653-1.73 2.058l-5.987 2.08c.36-1.112.422-2.263.128-3.39-.593-2.27-2.498-3.93-5.052-4.763l10.91-.553zm.963-17.32l.088.529c.334 2.096.47 4.014.551 7.324l.103 5.116c.02.746.043 1.357.076 2.042a.656.656 0 01-.895.638c-8.112-3.148-16.18-5.072-24.227-5.777V16.722c12.25 2.655 22.14 12.385 24.304 24.94zM41.14 16.74v34.779c-8.003.683-15.998 2.578-24.008 5.68a.657.657 0 01-.894-.561c-.957-13.514.832-22.234 7.652-29.79 4.812-5.329 10.54-8.696 17.25-10.108zm12.394-.278v34.966a79.534 79.534 0 00-11.05-.012l-.001-34.93a35.974 35.974 0 012.927-.37 31.904 31.904 0 018.124.346z"
    />
  </AvatarContainer>
);
