import { createClient as createContentfulClient, Asset } from "contentful";
import {
  Collection,
  GalleryEntry,
  PollEntry,
  ConsoleEntry,
  QuizEntry,
  StoryEntry,
  UnresolvedLink,
} from "../contentfulModels";

export async function loadCollection(): Promise<Collection> {
  // NSM Production Account
  const contentfulClient = createContentfulClient({
    space: "dfdkyj9lflco",
    accessToken: "8d5r-qPjD54bx6NSF4EqysOD114zCxFlzPuU2oD8c58",
    resolveLinks: false
  });

  const syncResponse = await contentfulClient.sync({
    initial: true,
    locale: "en-US"
  });

  // Entries and assets come from the sync API in the format
  // {
  //   fields: {
  //     "title": {
  //       "en-US": "My title"
  //     }
  //   }
  //   ...
  // }
  // These functions remap this to
  // {
  //   fields: {
  //     "title": "My title"
  //   }
  //   ...
  // }
  const entries = syncResponse.entries.map(entry => {
    entry.fields = Object.assign(
      {},
      ...Object.entries(entry.fields as object).map(([k, v]) => ({
        [k]: v["en-US"]
      }))
    );
    return entry;
  });

  const assets = syncResponse.assets.map(asset => {
    asset.fields = Object.assign(
      {},
      ...Object.entries(asset.fields as object).map(([k, v]) => ({
        [k]: v["en-US"]
      }))
    );
    return asset;
  });

  return Collection.fromEntriesAndAssets(entries, assets);
}

export function groupConsolesByGallery(
  collection: Collection
): Map<GalleryEntry, ConsoleEntry[]> {
  let out = new Map<GalleryEntry, ConsoleEntry[]>();

  // Initialize all galleries with empty consoles array
  for (let gallery of collection.galleries.values()) {
    out.set(gallery, []);
  }

  // Loop over all consoles, and for each grab their related showcases and
  // use that to traverse up to its gallery
  for (let console of collection.consoles.values()) {
    for (let showcaseLink of console.fields.showcases || []) {
      let showcase = collection.getShowcase(showcaseLink);
      if (showcase) {
        for (let [gallery, consoles] of out) {
          if (
            showcase.fields.gallery &&
            showcase.fields.gallery.sys.id === gallery.sys.id
          ) {
            consoles.push(console);
          }
        }
      }
    }
  }

  return out;
}

export function getAllStoriesForConsole(
  console: ConsoleEntry,
  collection: Collection
): StoryEntry[] {
  let out = [];
  for (let storyLink of console.fields.stories || []) {
    let story = collection.getStory(storyLink);
    if (story) {
      out.push(story);
    }
  }
  return out;
}

export function getAllPollsForConsole(
  console: ConsoleEntry,
  collection: Collection
): PollEntry[] {
  let out = [];
  let topLevelPoll = console.fields.poll
    ? collection.getPoll(console.fields.poll)
    : undefined;
  if (topLevelPoll) {
    out.push(topLevelPoll);
  }

  // traverse the console's stories and find any poll content items
  for (let story of getAllStoriesForConsole(console, collection)) {
    for (let contentItemLink of story.fields.contentItems || []) {
      try {
        // This will fail if the content item isn't a poll content module
        let pollContentModule = collection.getPollContentModule(
          contentItemLink
        );
        if (pollContentModule && pollContentModule.fields.poll) {
          let poll = collection.getPoll(pollContentModule.fields.poll);
          if (poll) {
            out.push(poll);
          }
        }
      } catch {
        continue;
      }
    }
  }

  return out;
}

export function getAllQuizzesForConsole(
  console: ConsoleEntry,
  collection: Collection
): QuizEntry[] {
  let out = [];

  // traverse the console's stories and find any quiz content items
  for (let story of getAllStoriesForConsole(console, collection)) {
    for (let contentItemLink of story.fields.contentItems || []) {
      try {
        // This will fail if the content item isn't a quiz content module
        let quizContentModule = collection.getQuizContentModule(
          contentItemLink
        );
        if (quizContentModule && quizContentModule.fields.quiz) {
          let quiz = collection.getQuiz(quizContentModule.fields.quiz);
          if (quiz) {
            out.push(quiz);
          }
        }
      } catch {
        continue;
      }
    }
  }

  return out;
}

export function groupPollsByGallery(
  collection: Collection
): Map<GalleryEntry, PollEntry[]> {
  let out = new Map();

  for (let [gallery, consoles] of groupConsolesByGallery(collection)) {
    let polls: PollEntry[] = [];

    for (let console of consoles) {
      polls = polls.concat(getAllPollsForConsole(console, collection));
    }

    out.set(gallery, polls);
  }

  return out;
}

export function groupQuizzesByGallery(
  collection: Collection
): Map<GalleryEntry, QuizEntry[]> {
  let out = new Map();

  for (let [gallery, consoles] of groupConsolesByGallery(collection)) {
    let quizzes: QuizEntry[] = [];

    for (let console of consoles) {
      quizzes = quizzes.concat(getAllQuizzesForConsole(console, collection));
    }

    out.set(gallery, quizzes);
  }

  return out;
}

type Fit = "fit" | "pad" | "fill" | "scale" | "crop" | "thumb";
type ContentfulImageUrlOptions = {
  width?: number;
  height?: number;
  fit?: Fit;
  // TODO focus, background colour
}

export function contentfulImageUrl(
  asset: Asset,
  options?: ContentfulImageUrlOptions
) {
  let params = new URLSearchParams({
    q: "90"
  });

  if (options !== undefined) {
    if (options.width !== undefined) {
      params.set("w", options.width.toString());
    }
    if (options.height !== undefined) {
      params.set("h", options.height.toString());
    }
    if (options.fit !== undefined && options.fit !== "fit") {
      params.set("fit", options.fit);
    }
  }

  const url = contentfulAssetUrl(asset);

  return `${url}?${params.toString()}`;
}

export function contentfulAssetUrl(asset: Asset) {
  const url = asset.fields.file.url;
  if (url.startsWith("http")) {
    return url;
  } else if (url.startsWith("//")) {
    return `https:${url}`;
  } else {
    throw Error(`Invalid asset url: ${url}`);
  }
}

export function contentfulImageLinkUrl(
  link: UnresolvedLink<"Asset">,
  collection: Collection,
  options?: ContentfulImageUrlOptions
): string | undefined {
  let asset = collection.getAsset(link);
  if (asset) {
    return contentfulImageUrl(asset, options);
  } else {
    return undefined;
  }
}
