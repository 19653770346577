import React, { useEffect } from "react";
import styled from "styled-components";

import { colours, StoryTheme } from "../styles/Themes";
import { fontSize, SlimHeading } from "../styles/Typography";
import { media } from "../styles/Layout";

import Button from "../components/Button";

const Background = styled.div<{ theme: StoryTheme }>`
  background: ${({ theme }) => theme.background2};
`;

const NewsletterWrapper = styled.div`
  max-width: 1024px;
  padding: 100px 0px;
  margin: 0 auto;
  text-align: center;
  color: ${({ theme }) => theme.text};
`;

const Form = styled.form`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 24px;
`;

const InputWrapper = styled.div`
  width: calc(100% - 16px);
  margin: 0 8px 8px;

  ${media.small} {
    margin: 0 16px 8px 0;
    width: calc(30% - 16px);
  }
`;

const Input = styled.input`
  border-radius: 8px;
  border: 2px solid ${colours.black35};
  width: 100%;
  height: 56px;
  font-size: ${fontSize.small};
  box-sizing: border-box;
  color: black;
  background-color: white;
  padding: 0 12px;

  &:focus {
    outline: none;
    border: 2px solid ${colours.black65};
  }
`;

const NewsletterForm = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src =
      "https://js.createsend1.com/javascript/copypastesubscribeformlogic.js";
    script.async = true;

    document.body.appendChild(script);
  }, []);

  return (
    <Background>
      <NewsletterWrapper>
        <SlimHeading>Get the latest from Australian Sports Museum</SlimHeading>
        <Form
          className="js-cm-form"
          id="subForm"
          action="https://www.createsend.com/t/subscribeerror?description="
          method="post"
          data-id="30FEA77E7D0A9B8D7616376B900632311D3CA6680F569149A3FF94394A51B0923619DBA62B5042589788DB754D103F0F2E3BFF39CC3685400E21FE30D940E1AA"
        >
          <InputWrapper>
            <Input
              placeholder="Name"
              aria-label="Name"
              id="fieldName"
              maxLength={200}
              name="cm-name"
            />
          </InputWrapper>
          <InputWrapper>
            <Input
              placeholder="Email"
              autoComplete="Email"
              aria-label="Email"
              className="js-cm-email-input"
              id="fieldEmail"
              maxLength={200}
              name="cm-sllvj-sllvj"
              required={true}
              type="email"
            />
          </InputWrapper>
          <Button type="submit">Subscribe</Button>
        </Form>
      </NewsletterWrapper>
    </Background>
  );
};

export default NewsletterForm;
