import React, { ReactNode, useState } from "react";
import { ThemeProvider } from "styled-components";
import { pluralize } from "../../utils";
import BadWordFilter from "bad-words";

// styles.
import {
  Container,
  Wrap,
  Button,
  Avatar,
  Statistics,
  Total,
  Result,
  Progress,
  ProgressBar,
  Divider,
  NicknameInputWrapper,
  NicknameInput,
  NicknameInputSubmit
} from "./VisitorSummary.styles";
import { getGalleryTheme, GalleryTheme } from "../../styles/Themes";

// icons.
import PollsIcon from '../icons/PollsIcon';
import TriviaIcon from '../icons/TriviaIcon';

// types.
import { ProfileResponse } from "../../userData";

// components 
import Stat from '../Stat'

type GalleryProgress = {
  id: number | undefined;
  percentage: number;
}

type Props = {
  totalCovered: number;
  profile: ProfileResponse;
  avatar: string | null;
  galleriesProgress: GalleryProgress[]
  children: ReactNode;
  onNicknameUpdated: (nickname: string) => void;
}

export default function VistorSummary({
  totalCovered,
  avatar,
  children,
  profile : {
    token_id,
    nickname,
    quizzes,
    polls,
  },
  galleriesProgress,
  onNicknameUpdated,
}: Props) {
  const [updatedNickname, setUpdatedNickname] = useState<string | null>(nickname);

  const submitNickname = () => {    
    if (updatedNickname !== null) {
      const badWordFilter = new BadWordFilter();

      let nickname = badWordFilter.clean(updatedNickname);
      // Limit nicknames to 20 characters
      nickname = nickname.slice(0, 20);

      setUpdatedNickname(nickname);
      onNicknameUpdated(nickname);
    }
  }

  return (
    <Container>
      <Wrap>
        
        <Progress>
          <Avatar avatarUrl={avatar} uid={token_id}/>
          {galleriesProgress
            .filter(({ id }) => {
              return id !== undefined && !!GalleryTheme[id]
            })
            .sort((a: GalleryProgress, b: GalleryProgress) => b.percentage - a.percentage)
            .map(({ id, percentage }) => {
              if (id !== undefined)  {
                return (!!GalleryTheme[id])
                  ? <ThemeProvider theme={getGalleryTheme(id)} key={`progress-${id}`}>
                      <ProgressBar width={percentage} />
                    </ThemeProvider>
                  : null
              } 
              return null
            })
          }
        </Progress>
        <NicknameInputWrapper isDirty={nickname !== updatedNickname}>
          <NicknameInput placeholder="Set a nickname" value={updatedNickname || ""} onChange={e => setUpdatedNickname(e.target.value)} />
          <NicknameInputSubmit onClick={submitNickname}>✔</NicknameInputSubmit>
        </NicknameInputWrapper>
        <Statistics>
          <div>
            <Total><span>{`${totalCovered}%`}</span> <span>Special features unlocked</span></Total>
          </div>
          <ul>
            <Result><Stat Icon={PollsIcon} title={`${polls.length} ${pluralize("Poll", polls.length)}`} /></Result>
            <Result><Stat Icon={TriviaIcon} title={`${quizzes.length} ${pluralize("Quiz", quizzes.length)}`} /></Result>
          </ul>
        </Statistics>
        <Divider>
          <Button url='#results'>Jump to results</Button>
        </Divider>
      </Wrap>
      {children}
    </Container>
  );
}
