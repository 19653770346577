import React from "react";

import Icon, { IconSize } from "./Icon";

type Props = {
  size: IconSize
}

const TriviaIcon = ({ size, ...rest }: Props) => (
  <Icon {...rest} size={size}>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <g fill="none" fillRule="evenodd"><rect width="24" height="24" fill="#000" rx="2"/><path fill="#FFF" d="M20 4v16H4z"/></g>
    </svg>
  </Icon>
);

export default TriviaIcon;