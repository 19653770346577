import React, { Fragment } from 'react';
import { pluralize } from "../../utils";

// components.
import Count from '../Count';
import Slideshow from '../Slideshow';

// styles.
import { Wrap, Container, Header, Slide, Card, CardTitle, CardScore, CardEmpty, Lock } from './GameOn.styles';
import { SlimHeading } from '../../styles/Typography';

// types
import { GameOnProfile } from '../../userData';

// gifs.
import basketball from "../../images/game-on/basketball.gif";
import cycling from "../../images/game-on/cycling.gif";
import horseRacing from "../../images/game-on/horse-racing.gif";
import netball from "../../images/game-on/netball.gif";
import pressureCooker from "../../images/game-on/pressure-cooker.gif";
import reactionTimer from "../../images/game-on/reaction-timer.gif";
import rugby from "../../images/game-on/rugby.gif";
import soccer from "../../images/game-on/soccer.gif";
import surfing from "../../images/game-on/surfing.gif";

// utils.
const sum = (numbers: number[]): number => {
  return numbers.reduce((total, x) => total + x, 0)
}
const ordinalSuffix = (n: number ) => ["st","nd","rd"][(((n < 0 ? -n : n) + 90) % 100 - 10 ) % 10 - 1] || "th"
const distance = (n: number) => {
  const isKM = n / 1000 > 1
  const suffix = isKM ? 'KM' : 'M';
  const distance = isKM ? n / 1000 : n;
  return {
    distance,
    suffix,
  }
}

const surfStatus = (n:number) => {
  if(n <= 15) {
    return 'Kook'
  }
  if(n >= 16 && n <= 30) {
    return 'Dude'
  }
  if(n >= 31 && n <= 45) {
    return 'Legend'
  }
  if(n >= 46) {
    return 'Bloody ripper'
  }
  else {
    return null;
  }
}

type GameOnDetail = {
  title: string;
  suffix: string;
  score: number | string | null;
  url: string;
  color: string;
}

const GAME_ON_SECTIONS = (scores: GameOnProfile): GameOnDetail[] => [
  { 
    title: 'Basket ball',
    suffix: sum(scores.basketball_points || []) === 1 ? 'Point' : 'Points',
    score: scores.basketball_points && sum(scores.basketball_points),
    url: basketball,
    color: '#ffb85b',
  },
  {
    title: 'Hand Cycling',
    suffix: scores.cycle_distance ? distance(scores.cycle_distance).suffix : '',
    score: scores.cycle_distance && distance(scores.cycle_distance).distance,
    url: cycling,
    color: '#ffb85b',
  },
  {
    title: 'Horse Racing',
    suffix: '',
    score: scores.horse_position && `${scores.horse_position}${ordinalSuffix(scores.horse_position)} Place`,
    url: horseRacing,
    color: '#71a85f',
  },
  {
    title: 'Netball',
    suffix: pluralize('Point', sum(scores.netball_points || [])),
    score: scores.netball_points && sum(scores.netball_points),
    url: netball,
    color: '#ffb85b',
  },
  {
    title: 'Take the heat',
    suffix: pluralize('Point', sum(scores.pressure_cooker_points || [])),
    score: scores.pressure_cooker_points && sum(scores.pressure_cooker_points),
    url: pressureCooker,
    color: '#018b66',
  },
  {
    title: "Reaction Timer",
    suffix: scores.reaction_time_score === 1 ? 'Point' : 'Points',
    score: scores.reaction_time_score && scores.reaction_time_score,
    url: reactionTimer,
    color: '#ffb85b',
  },
  {
    title: 'Rugby League',
    suffix: pluralize('Point', sum(scores.rugby_league_points || [])),
    score: scores.rugby_league_points && sum(scores.rugby_league_points),
    url: rugby,
    color: '#018b66',
  },
  {
    title: 'Rugby Union',
    suffix: pluralize('Point', sum(scores.rugby_union_points || [])),
    score: scores.rugby_union_points && sum(scores.rugby_union_points),
    url: rugby,
    color: '#90cedb',
  },
  {
    title: 'Football',
    suffix: pluralize('Point', sum(scores.soccer_points || [])),
    score: scores.soccer_points && sum(scores.soccer_points),
    url: soccer,
    color: '#018b66',
  },
  {
    title: 'Surfing',
    suffix: '',
    score: scores.surfing_time && surfStatus(scores.surfing_time),
    url: surfing,
    color: '#90cedb',
  },
]

const makeCards = (data: GameOnDetail[] ) => {
  return data
    .sort(
      (a:GameOnDetail, b: GameOnDetail) => {
        if (a.score !== null && b.score !== null && a.score !== undefined && b.score !== undefined) {
          return -(a.score > b.score) || +(a.score < b.score);
        }
        return Number(a.score === null) -  Number(b.score === null);
      }
    )
    .map(({ title, url, color, suffix, score }: GameOnDetail ) => (
      (score !== null)
        ? <Slide key={`slide-${title}'`}>
            <Card color={color}>
              <img src={url} alt={title} />
              <CardTitle>{title}</CardTitle>
              <CardScore>{score} <span>{suffix}</span></CardScore>
            </Card>
          </Slide>
        : <Slide key={`slide-${title}'`}>
            <CardEmpty>
              <strong>{title}</strong>
              <Lock size={'small'} />
            </CardEmpty>
          </Slide>
    ))
}

const GameOn = ({ scores }: { scores: GameOnProfile }) => {
  const data = GAME_ON_SECTIONS(scores);
  const Cards = makeCards(data);
  const completed = data.filter(({ score }: GameOnDetail) => score !== null).length
  return(
    <Fragment>
      <Container>
        <Wrap>
          <Header>
            <SlimHeading uppercase={true}>Your Game on scores</SlimHeading>
            <Count total={10} amount={completed} divider={'/'} />
          </Header>
       
        <Slideshow
          slides={Cards}
          slidesToShow={4}
          slidesToScroll={4}
          infinite={false}
          themed={true}
          responsive={[
            { breakpoint: 1024, settings: { slidesToShow: 4, slidesToScroll: 4 } },
            { breakpoint: 900, settings: { slidesToShow: 3, slidesToScroll: 3 } },
            { breakpoint: 600, settings: { slidesToShow: 2, slidesToScroll: 2 } }
          ]}
        />
         </Wrap>
      </Container>
    </Fragment>
  )
}

export default GameOn;
