import React, { ReactNode } from "react";
import styled from "styled-components";
import { fontSize,fontFamily } from '../styles/Typography';
import { StoryTheme, colours } from "../styles/Themes";

const Element = styled.a<{ theme?: StoryTheme, disabled?: boolean }>`
  font-size: ${fontSize.medium};
  font-family: ${fontFamily.middleWeight};
  letter-spacing: normal;
  text-align: center;
  padding: 14px 8px;
  height: 56px;
  transition: color .33s linear, background .33s linear;
  background: ${colours.darkGrey};
  color: ${colours.white};
  ${({ theme }) => !!theme ? `
    background: ${theme.buttonGradient};
    color: ${theme.text};
  `: null} 
  text-decoration: none;
  border-radius: 8px;
  max-width: 288px;
  display: block;
  border: none;
  width: 100%;
  cursor: pointer;
  ${({ disabled }) => disabled ? `
    pointer-events: none;
    opacity: 0.5;
  ` : ''};
  ${({ theme, disabled }) => !disabled ? `
    &:hover {
      background: ${colours.white};
      color: ${colours.darkGrey};
      ${!!theme ? `
        background: ${theme.buttonGradient};
        color: ${theme.text};
      `: ''} 
    }`: ''
  }
`;

type Props = {
  children: ReactNode;
  url?: string;
  type?: string;
  onClick?: () => void;
  disabled?: boolean;
}

export default function Button({children, url, ...rest} : Props) {
  const ElementType = url ? 'a' : 'button';
  return (
    <Element as={ElementType} {...rest} href={url}>
      {children}
    </Element>
  );
}
