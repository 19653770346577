import React from "react";

import Icon, { IconSize } from "./Icon";

type Props = {
  size: IconSize
}

const ArrowRight = ({ size, ...rest }: Props) => (
  <Icon {...rest} size={size}>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 64">
    <path fillRule="evenodd" stroke="#FFF" strokeOpacity=".648" d="M13.145 32.369L22.678 1.5h-4.903L1.433 32.373 17.779 62.5h4.904l-9.538-30.131z"/>
    </svg>
  </Icon>
);

export default ArrowRight;