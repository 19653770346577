import React from "react";

import AvatarContainer from "./AvatarContainer";

export default (
  <AvatarContainer>
    <path
      fill="inherit"
      d="M48 4c24.3 0 44 19.7 44 44S72.3 92 48 92 4 72.3 4 48 23.7 4 48 4zm0 4C25.909 8 8 25.909 8 48s17.909 40 40 40 40-17.909 40-40S70.091 8 48 8zm0 8c17.673 0 32 14.327 32 32 0 17.673-14.327 32-32 32-17.673 0-32-14.327-32-32 0-17.673 14.327-32 32-32zm7.04 42.699H40.86l-7.09 9.579 7.09 9.578h14.18l7.09-9.578-7.09-9.579zm-30.828-17.81l-7.085 9.751 4.463 13.558 11.473 3.751 7.085-9.752-4.333-13.578-11.603-3.73zm47.764.057l-11.459 3.496-4.568 13.5 6.914 9.874 11.535-3.55L78.89 50.82l-6.914-9.874zM60.092 19.592l-11.485 3.71v12.053l11.485 8.438 11.393-3.709V28.03l-11.393-8.438zm-23.917 0l-11.58 8.345v12.055l11.58 3.801 11.487-8.438V23.301l-11.487-3.709z"
    />
  </AvatarContainer>
);
