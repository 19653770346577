import React from "react";

import AvatarContainer from "./AvatarContainer";

export default (
  <AvatarContainer>
    <path
      fill="inherit"
      d="M48 4c24.3 0 44 19.7 44 44S72.3 92 48 92 4 72.3 4 48 23.7 4 48 4zm0 4C25.909 8 8 25.909 8 48s17.909 40 40 40 40-17.909 40-40S70.091 8 48 8zM32.334 31.54c14.433-14.504 33.594-18.895 42.8-9.804 9.204 9.09 4.966 28.218-9.467 42.724-14.434 14.505-33.596 18.895-42.8 9.804-9.206-9.092-4.967-28.219 9.467-42.724zm-4.837 36.735l-1.847 1.84a.649.649 0 000 .92.655.655 0 00.924 0l1.847-1.84a.649.649 0 000-.92.655.655 0 00-.924 0zm3.694-3.68l-1.847 1.84a.649.649 0 000 .92.655.655 0 00.924 0l1.847-1.84a.649.649 0 000-.92.655.655 0 00-.924 0zm3.695-3.681l-1.847 1.84a.649.649 0 000 .92.655.655 0 00.923 0l1.847-1.84a.649.649 0 000-.92.655.655 0 00-.923 0zm3.694-3.68l-1.847 1.84a.649.649 0 000 .92.655.655 0 00.923 0l1.847-1.84a.649.649 0 000-.92.655.655 0 00-.923 0zm3.694-3.68l-1.847 1.84a.649.649 0 000 .92.655.655 0 00.923 0l1.848-1.84a.649.649 0 000-.92.655.655 0 00-.924 0zm3.694-3.681l-1.847 1.84a.649.649 0 000 .92.655.655 0 00.924 0l1.847-1.84a.649.649 0 000-.92.655.655 0 00-.924 0zm3.694-3.68l-1.847 1.84a.649.649 0 000 .92.655.655 0 00.924 0l1.847-1.84a.649.649 0 000-.92.655.655 0 00-.924 0zm3.695-3.681l-1.847 1.84a.649.649 0 000 .92.655.655 0 00.923 0l1.847-1.84a.649.649 0 000-.92.655.655 0 00-.923 0zm3.694-3.68l-1.847 1.84a.649.649 0 000 .92.655.655 0 00.923 0l1.847-1.84a.649.649 0 000-.92.655.655 0 00-.923 0zm3.694-3.68l-1.847 1.84a.649.649 0 000 .92.655.655 0 00.923 0l1.848-1.84a.649.649 0 000-.92.655.655 0 00-.924 0zm3.694-3.681l-1.847 1.84a.649.649 0 000 .92.655.655 0 00.924 0l1.847-1.84a.649.649 0 000-.92.655.655 0 00-.924 0zm3.694-3.68l-1.847 1.84a.649.649 0 000 .92.655.655 0 00.924 0l1.847-1.84a.649.649 0 000-.92.655.655 0 00-.924 0zm3.695-3.68l-1.847 1.84a.649.649 0 000 .92.655.655 0 00.923 0l1.847-1.84a.649.649 0 000-.92.655.655 0 00-.923 0z"
    />
  </AvatarContainer>
);
