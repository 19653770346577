import { PollEntry } from "../contentfulModels";
import { PollSummary, ProfilePollSummary } from "../userData";

export type GroupedPoll = {
  poll: PollEntry;
  result: PollSummary | null;
  profileResult: ProfilePollSummary | null;
};
export function groupPollData({
  polls,
  results,
  profileResults
}: {
  polls: PollEntry[];
  results: PollSummary[];
  profileResults: ProfilePollSummary[];
}): GroupedPoll[] {
  return polls.map(poll => {
    let pollId = poll.sys.id;
    return {
      poll: poll,
      result: results.find(result => result.poll_id === pollId) || null,
      profileResult:
        profileResults.find(result => result.poll_id === pollId) || null
    };
  });
}
