export type Session = {
  privateId: string;
  shortCode: string;
  visitDate: string;
};

export function loadSession(): Session | null {
  let sessionString = window.localStorage.getItem("session");
  if (sessionString !== null) {
    // TODO validate this JSON matches up with the current session type
    return JSON.parse(sessionString);
  } else {
    return null;
  }
}

export function storeSession(session: Session | null) {
  if (session !== null) {
    window.localStorage.setItem("session", JSON.stringify(session));
  } else {
    window.localStorage.removeItem("session");
  }
}
