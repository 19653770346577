import React from "react";

import AvatarContainer from "./AvatarContainer";

export default (
  <AvatarContainer>
    <path
      fill="inherit"
      d="M48 4c24.3 0 44 19.7 44 44S72.3 92 48 92 4 72.3 4 48 23.7 4 48 4zm0 4C25.909 8 8 25.909 8 48s17.909 40 40 40 40-17.909 40-40S70.091 8 48 8zM19.257 52.726l.631-.841c2.13-2.916 6.222-3.562 9.14-1.434 2.927 2.134 3.581 6.266 1.458 9.21-.076.105-.16.22-.246.326l-.127.154c-.096.11-.177.211-.254.317-.997 1.382-1.06 3.248-.064 4.63 1.657 2.303 4.805 2.218 6.393.233l.122-.16 2.838-3.934 10.623 7.745-2.839 3.934c-5.47 7.582-15.992 9.348-23.608 4.027l-.284-.203c-7.625-5.56-9.321-16.328-3.783-24.004zm22.861 4.388l10.622 7.745-2.15 2.978-10.622-7.746 2.15-2.977zm.82-1.136L69.128 19.7a1.681 1.681 0 012.243-.452l.128.087 7.82 5.85c.699.523.881 1.484.447 2.222l-.088.136-26.119 36.181-10.621-7.746L69.128 19.7l-26.19 36.278z"
    />
  </AvatarContainer>
);
