export const pluralize = (singular: string, n: number): string => {
    if (n === 1) {
        return singular
    } else {
        if (singular.endsWith('z')) {
            return `${singular}zes`
        } else {
            return `${singular}s`
        }
    }
}
