import React from 'react';
import ReactPlayer from 'react-player';
import styled from 'styled-components';

const Player = styled(ReactPlayer)`
  video:focus {
    outline: none;
  }
`
export const Thumbnail = styled.a`
  border: 3px solid #fff;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.15);
  height: 0;
  padding-top: 56.25%;
  overflow: hidden;
  display: block;
  background-size: cover;
  cursor: pointer;
  position: relative;
  &:after {
    content: "";
    height: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -10px;
    margin-top: -20px;
    border-color: transparent transparent transparent #fff;
    border-width: 20px 30px;
    border-style: solid;
  }
  & > * {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
`


type Props = {
  url: string;
  onClick?: () => void;
}

const VideoPlayer = ({ url }: Props ) =>{
  return <Player width="100%" height="auto" url={`${url}#t=0.1`} light={false} controls={true} /> 
}

export default VideoPlayer