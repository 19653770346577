import styled from 'styled-components';
import { space } from '../../styles/Layout';

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100%;
  height: 100%;
  padding: ${space(1)};
  background: rgba(0, 0, 0, 0.5);
  pointer-event: none;
`
export const Container = styled.div`
  padding-left: ${space(1)};
  padding-right: ${space(1)};
  margin: 0 auto;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
  width: 100%;
  max-width: 680px;
  max-height: 100%;
`

export const CloseButton  = styled.div`
  position: absolute;
  z-index: 10;
  top: ${space(1)};
  right: ${space(2)};
  border: none;
  cursor: pointer;
  &:hover {
    
  }
`