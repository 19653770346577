import styled from "styled-components";
import { StoryTheme, colours } from "../../styles/Themes";
import { fontSize, fontFamily } from "../../styles/Typography";
// layout.
import { WrapStyles, space, TwoColumn, media } from '../../styles/Layout';
// components.
import AvatarComponent from '../Avatar'
import ButtonComponent from '../Button'


export const Container = styled.div<{ theme: StoryTheme }>`
  background: ${({ theme }) => theme.gradient1 };
  color: ${({ theme }) => theme.text };
`

export const Wrap = styled.div`
  ${WrapStyles}
  min-height: 300px;
  padding-top: ${space(3)};
`

export const Button = styled(ButtonComponent)`
  width: 100%;
  max-width: 100%;
  ${media.small} {
    max-width: 288px;
  }
`

export const Avatar = styled(AvatarComponent)`
  text-align: center;
  margin-bottom: ${space(2)};
`

export const Progress = styled.div`
  position: relative;
  width: 100%;
  margin: 0 auto;
  display: block;
  height: 128px;
  padding-top: 26px;
  ${Avatar} {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
  }
  ${media.medium} {
    width: 60%;
  }
`

export const ProgressBar = styled.div<{ width: number, theme: StoryTheme }>`
  ${({ width, theme }) => (`
    width: ${width}%;
    background: ${theme.progressBar};
   
  `)}
  border: 1px solid rgba(255, 255, 255, 0.25);
  box-shadow:0px 0px 0px 1px rgba(0, 0, 0, 0.25);
  display: block;
  margin: 0 auto;
  height: 10px;
  margin-bottom: 3px;
  border-radius: 5px;
  max-width: 100%;

  &:nth-child(4), 
  &:nth-child(5){
    min-width: 118px;
  }
  &:nth-child(3), 
  &:nth-child(6){
    min-width: 102px;
  }
  &:nth-child(2), 
  &:nth-child(7){
    min-width: 85px;
  }
`

export const Heading = styled.h2`
  font-size: ${fontSize.giant};
  font-family: ${fontFamily.fullLightWeight};
  color: inherit;
  text-align: center;
  display: block;
  margin-bottom: ${space(2)};
  margin-top: -${space(2)};  
`

export const Statistics = styled.div<{ theme: StoryTheme }>`
  ${TwoColumn}
  margin-bottom: ${space(3)};
  & > * {
    height: 56px;
    padding-left: ${space(2)};
    padding-right: ${space(2)};
    display: flex;
    &:last-child {
      background: ${({ theme }) => theme.black35 };
      border-radius: 8px;
    }
  }
`

export const Total = styled.strong`
  text-align: center;
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  span:first-child {
    font-family: ${fontFamily.fullCruiserWeight};
    font-size: ${fontSize.giant};
    display: flex;
    align-self: center;
  }
  span:last-child {
    text-align: left;
    margin-left: ${space(3)};
    font-family: ${fontFamily.juniorWelterWeight};
    text-transform: uppercase;
    font-size: 18px;
    display: flex;
    max-width: 150px;
    align-self: center;
  }
`

export const Divider = styled.div`
  display: flex;
  align-items: center;
  &:before,
  &:after {
    content: '';
    width: 30%;
    display: none;
    height: 1px;
    background: ${colours.white65};
  }
  &:before {
    margin-right: ${space(6)};
  }
  &:after {
    margin-left: ${space(6)};
  }

  ${media.small} {
    &:before,
    &:after {
      display: block;
    }
  }
`;

export const Result = styled.div<{ theme: StoryTheme }>`
  width: 50%;
  padding-top: ${space(2)};
`;


export const NicknameInputSubmit = styled.button`
  position: absolute;
  display: none;
  right: 4px;
  top: 4px;
  height: calc(100% - 8px);
  width: 72px;
  background: white;
  border: none;
  border-radius: 3px;
  font-family: ${fontFamily.fullLightWeight};
  font-size: ${fontSize.medium}
  border: 2px solid rgba(255, 255, 255, 0.20);
  background: rgba(255, 255, 255, 0.1);
  color: white;

  &:focus {
    outline: none;
  }

  &:hover {
    border: 2px solid rgba(255, 255, 255, 0.35);
    background: rgba(255, 255, 255, 0.20);
    cursor: pointer;
  }
`;

export const NicknameInput = styled.input`
  background: rgba(0,0,0,0);
  border: 2px solid rgba(0, 0, 0, 0);
  width: 100%;
  height: 70px;
  border-radius: 8px;
  text-align: center;
  text-transform: uppercase;
  color: white;
  font-size: ${fontSize.giant};
  font-family: ${fontFamily.fullLightWeight};

  &:focus {
    outline: none;
    background: rgba(0,0,0,0.05);
    border: 2px solid rgba(255,255,255,0.35);
    font-size: ${fontSize.xxlarge};
  }

  &:hover {
    background: rgba(255,255,255, 0.05);
    cursor: pointer;
  }

  &::placeholder {
    font-size: ${fontSize.medium};
    font-family: ${fontFamily.juniorWelterWeight};
    color: rgba(255, 255, 255, 0.6);
    position: relative;
    top: -12px;
  }
`;

export const NicknameInputWrapper = styled.div<{ isDirty: boolean }>`
  position: relative;
  margin: 0 0 16px;

  ${({ isDirty }) => isDirty ?  `
    ${NicknameInputSubmit} {
      display: block;
    }

    ${NicknameInput} {
      background: rgba(0,0,0,0.05);
      border: 2px solid rgba(255,255,255,0.35)
    }
  ` : ""}
`;
