import { QuizEntry } from "../contentfulModels";
import { QuizResult } from "../userData";

export type GroupedQuiz = {
  quiz: QuizEntry;
  result: QuizResult | null;
};

export function groupQuizData({
  quizzes,
  results
}: {
  quizzes: QuizEntry[];
  results: QuizResult[];
}): GroupedQuiz[] {
  return quizzes.map(quiz => {
    return {
      quiz: quiz,
      result: results.find(result => quiz.sys.id === result.quiz_id) || null
    };
  });
}
