import React, { ReactNode, useState } from 'react';
import Slider from "react-slick";
import { Container, Markers, Marker, SlideshowButton, ButtonWrap, SlideCount } from './Slideshow.styles';
import ArrowLeft from '../icons/ArrowLeft';
import ArrowRight from '../icons/ArrowRight';

type Props = {
  slides: ReactNode[];
  slidesToShow?: number;
  slidesToScroll?: number;
  infinite?: boolean,
  lightTheme?: boolean;
  showSlideCount: boolean;
  themed?: boolean;
  onSlideChange?: (index: number) => void;
  responsive?: Array<{ breakpoint: number; settings: {}; }>;
}

const PreviousArrow = (props: { lightTheme?: boolean, to: string, themed?: boolean, hide: boolean }) : JSX.Element => {
  return (
    <ButtonWrap lightTheme={props.lightTheme} themed={props.themed}>
      <SlideshowButton {...props} lightTheme={props.lightTheme} themed={props.themed} hide={props.hide}>
        <ArrowRight size='small' />
      </SlideshowButton>
    </ButtonWrap>
  )
}

const NextArrow = (props: { lightTheme?: boolean, to: string, themed?: boolean, hide: boolean }) : JSX.Element => {
  return (
    <ButtonWrap isNext={true} lightTheme={props.lightTheme} themed={props.themed}>
      <SlideshowButton {...props} isNext={true} lightTheme={props.lightTheme} themed={props.themed} hide={props.hide}>
        <ArrowLeft size='small' />
      </SlideshowButton>
    </ButtonWrap>
  )
}

const SETTINGS = {
  dots: true,
  speed: 500,
  adaptiveHeight: false,
};

const Dots = (dots: ReactNode[]) => {
  return <Markers>{dots}</Markers>
}

const Dot = (themed?: boolean) => (i: number) => {
  return <Marker themed={themed}><span>{ i + 1}</span></Marker>
}


const Slideshow = ({ slides, lightTheme, themed, showSlideCount, onSlideChange, ...rest }: Props) => {
  const [currentSlide, setCurrentSlide] = useState<number>(1)
  if(!slides.length) return null
  const nav = {
    prevArrow: <PreviousArrow to='prev' lightTheme={lightTheme} themed={themed} hide={!rest.infinite ? currentSlide === 1 : false}/>,
    nextArrow: <NextArrow to='next' lightTheme={lightTheme} themed={themed} hide={!rest.infinite ? currentSlide >= (slides.length - 1) : false}/>,
  }
  const onChange = (current: number, next: number) => {
    setCurrentSlide(next + 1);
    if(onSlideChange) onSlideChange(next)
  }
  const hasSlideCount:boolean = (showSlideCount && slides.length > 1);
  const props = {
    ...SETTINGS,
    ...nav,
    ...rest,
  }
  const dot = Dot(themed)
  return (
    <Container lightTheme={lightTheme} slideCount={hasSlideCount} themed={themed}>
      {hasSlideCount && <SlideCount>{currentSlide} of {slides.length}</SlideCount>}
      <Slider {...props} customPaging={dot} appendDots={Dots} beforeChange={onChange}>
        {slides.map((Slide: ReactNode) => Slide)}
      </Slider>
    </Container>
  );
}

Slideshow.defaultProps = {
  slidesToShow: 1,
  slidesToScroll: 1,
  lightTheme: false,
  showSlideCount: false,
  infinite: true,
  themed: false,
  responsive: [],
}


export default Slideshow;