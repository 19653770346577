import styled from "styled-components";
import { space } from "../../styles/Layout";
import { SlimHeading } from '../../styles/Typography';
import { colours } from "../../styles/Themes";

export const Container = styled.div`
  padding-top: ${space(7)};
  padding-left: ${space(1)};
  padding-right: ${space(1)};
  padding-bottom: ${space(2)};
  background: ${colours.white};
  
  > div {
    padding-top: 0;
    padding-bottom: ${space(2)};
  }

  .slick-slider {
    margin: 0;
  }

  .slick-slide img {
    max-width: 100%;
  }
`

export const Title = styled(SlimHeading)`
  padding-left: ${space(1)};
`

export const Share = styled.div`
  display: flex;
  justify-content: center;
  margin-top: ${space(1)};
`
