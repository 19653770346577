import styled from 'styled-components';

export const fontSize = {
  xxsmall: '9px',
  xsmall: '12px',
  small: '15px',
  medium: '24px',
  large: '30px',
  xlarge: '36px',
  xxlarge: '48px',
  giant: '56px',
}

export const fontFamily = {
  juniorFeatherWeight: `"Knockout 28 A", "Knockout 28 B"`,
  juniorWelterWeight: `"Knockout 30 A", "Knockout 30 B"`,
  flyWeight: `"Knockout 46 A", "Knockout 46 B"`,
  middleWeight: `"Knockout 51 A", "Knockout 51 B"`,
  cruiserWeight: `"Knockout 52 A", "Knockout 52 B"`,
  fullCruiserWeight: `"Knockout 72 A", "Knockout 72 B"`,
  fullFeatherWeight: `"Knockout 68 A", "Knockout 68 B"`,
  fullLightWeight: `"Knockout 69 A", "Knockout 69 B"`,
  ultimateMiddleWeight: `"Knockout 91 A", "Knockout 91 B"`,
  ultimateCruiserWeight: `"Knockout 92 A", "Knockout 92 B"`,
}

export const SlimHeading = styled.h2<{ uppercase?: boolean }>`
  font-family: ${fontFamily.juniorWelterWeight};
  font-size: ${fontSize.medium};
  text-transform: ${({ uppercase }) => uppercase ? 'uppercase' : 'normal'}
`

export const BoldHeading = styled.h2`
  font-family: ${fontFamily.ultimateCruiserWeight};
  font-size: ${fontSize.large};
  letter-spacing: 0.16px;
  text-transform: uppercase;
`