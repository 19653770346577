import React from "react";
import styled from "styled-components";
import { StoryTheme } from "../../styles/Themes";
import Result, { ResultProps } from "./Result";
import { space } from "../../styles/Layout";

const Container = styled.div`
  width: 50%;
  display: flex;
  flex-direction: row;
`;

const CircleContainer = styled.div<{ percent: number }>`
  width: ${({ percent }) => percent >= 0.5 ? '65%' : '35%'};
  min-width: 25%;
  max-width: 75%;
  align-self: center;
  display: flex;
  position: relative;
  &:first-child {
    margin-right: ${space(1)};
  }
  &:last-child {
    margin-left: ${space(1)};
  }
`

const Image = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-content: center;
  img {
    align-self: center;
    display: block;
    width: 100%;
    max-width: 65%;
  }

`

const Circle = styled.div<{ percent: number, theme: StoryTheme }>`
  width: 100%;
  height:0;
  padding-bottom: 100%;
  border-radius: 50%;
  background-color: ${({ theme, percent }) => percent >= 0.5 ? theme.background1 : theme.lightGrey};
  
`;

const CircleScaleResult = ({
  responsePercentLeft,
  responsePercentRight,
  leftImageUrl,
  rightImageUrl,
  ...props
}: ResultProps) => {
  return (
    <Result
      responsePercentLeft={responsePercentLeft}
      responsePercentRight={responsePercentRight}
      {...props}
    > 
      <Container>
        <CircleContainer percent={responsePercentLeft}>
          <Circle percent={responsePercentLeft}></Circle>
          {leftImageUrl && <Image><img src={leftImageUrl} alt={props.leftText || ''}/></Image>}
        </CircleContainer>
        <CircleContainer percent={responsePercentRight}>
          <Circle percent={responsePercentRight}></Circle>
          {rightImageUrl && <Image> <img src={rightImageUrl} alt={props.rightText || ''}/></Image>}
        </CircleContainer>
      </Container>
    </Result>
  );
};

export default CircleScaleResult;
