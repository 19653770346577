import { StoryThemeColor } from "../contentfulModels";
import md5 from 'md5';

// Returns a linear gradient.
const gradient = (color1:string, color2: string): string => `linear-gradient(to bottom, ${color1}, ${color2});`

export const colours = {
  lightGrey: "#bdbdbd",
  grey: "#373737",
  darkGrey: "#101010",
  white: "#ffffff",
  white65: "rgba(255, 255, 255, 0.65)",
  black65: "rgba(0, 0, 0, 0.65)",
  black35: "rgba(0, 0, 0, 0.35)",
}

export type StoryTheme = {
  pop1: string;
  pop2: string;
  pollMinor: string;
  highlight: string;
  background1: string;
  background2: string;
  background3: string;
  gradient1: string;
  gradient2: string;
  buttonGradient: string;
  text: string;
  titleColor: string;
  lightGrey: string;
  grey: string;
  darkGrey: string;
  white: string;
  white65: string;
  black65: string;
  black35: string;
  textShadowSmall: string;
  textShadowLarge: string;
  line1: string;
  line2: string;
  line3: string;
  progressBar: string;
}
const defaultTheme: StoryTheme = {
  pop1: "#5becdd",
  pop2: "#5becdd",
  pollMinor: "#ffcb4b",
  highlight: "#3B7DE9",
  background1: "#0B5ADA",
  background2: "#0140A7",
  background3: "#04317B",
  gradient1: gradient('#0B5ADA', '#0140A7'), //  background1 to background2
  gradient2: gradient('#04317B', '#0140A7'), // background3 to background2
  buttonGradient: gradient('#3B7DE9', '#0B5ADA'), // highlight to background1
  text: "white",
  titleColor: "white",
  lightGrey: "#bdbdbd",
  grey: "#373737",
  darkGrey: "#101010",
  white: "#ffffff",
  white65: "rgba(255, 255, 255, 0.65)",
  black65: "rgba(0, 0, 0, 0.65)",
  black35: "rgba(0, 0, 0, 0.35)",
  textShadowSmall: "0 2px 2px rgba(0, 0, 0, 0.65)",
  textShadowLarge: "0 16px 8px rgba(0, 0, 0, 0.12)",
  line1: "#0B5ADA",
  line2: "#5becdd",
  line3: "#0B5ADA",
  progressBar: '#5becdd',
};

const turquoise: StoryTheme = {
  ...defaultTheme,
  pop1: "#fff86e",
  pop2: "#fff86e",
  pollMinor: "#004f58",
  highlight: "#8AFEE8",
  background1: "#5BECDD",
  background2: "#19BCAC",
  background3: "#02897C",
  gradient1: gradient('#5BECDD', '#19BCAC'), //  background1 to background2
  gradient2: gradient('#02897C', '#19BCAC'), // background3 to background2
  buttonGradient: gradient('#8AFEE8', '#5BECDD'), // highlight to background1
  text: "#004f58",
  textShadowSmall: "0 1px 1px rgba(255, 255, 255, 0.35)"
};
const teal: StoryTheme = {
  ...defaultTheme,
  pop1: "#fff86e",
  pop2: "#fff86e",
  pollMinor: "#ff7a43",
  highlight: "#56D7DA",
  background1: "#11A4A7",
  background2: "#108285",
  background3: "#085E60",
  gradient1: gradient('#11A4A7', '#108285'), //  background1 to background2
  gradient2: gradient('#085E60', '#108285'), // background3 to background2
  buttonGradient: gradient('#56D7DA', '#11A4A7'), // highlight to background1
  text: "white"
};
const lightGreen: StoryTheme = {
  ...defaultTheme,
  pop1: "#FFFA00",
  pop2: "#fff86e",
  pollMinor: "#1a5f00",
  highlight: "#B4EF2A",
  background1: "#92C61A",
  background2: "#6B9902",
  background3: "#517107",
  gradient1: gradient('#92C61A', '#6B9902'), //  background1 to background2
  gradient2: gradient('#517107', '#6B9902'), // background3 to background2
  buttonGradient: gradient('#B4EF2A', '#92C61A'), // highlight to background1
  text: "white"
};
const green: StoryTheme = {
  ...defaultTheme,
  pop1: "#ffcd4d",
  pop2: "#fff86e",
  pollMinor: "#67f167",
  highlight: "#20B85F",
  background1: "#078F40",
  background2: "#046C30",
  background3: "#004E0A",
  gradient1: gradient('#078F40', '#046C30'), //  background1 to background2
  gradient2: gradient('#004E0A', '#046C30'), // background3 to background2
  buttonGradient: gradient('#20B85F', '#078F40'), // highlight to background1
  text: "white"
};
const yellow: StoryTheme = {
  ...defaultTheme,
  pop1: "#137d3f",
  pop2: "#0aa200",
  pollMinor: "#ffea3e",
  highlight: "#FFCD4D",
  background1: "#ffcc6f",
  background2: "#ffb500",
  background3: "#ffd263",
  gradient1: gradient('#ffcc6f', '#ffb500'), //  background1 to background2
  gradient2: gradient('#ffd263', '#ffb500'), // background3 to background2
  buttonGradient: gradient('#FFCD4D', '#ffcc6f'), // highlight to background1
  text: "#482c0c",
  titleColor: "white",
  textShadowSmall: "0 1px 1px rgba(255, 255, 255, 0.35)",
  textShadowLarge: "0 8px 4px rgba(0,0,0,0.20)"
};
const orange: StoryTheme = {
  ...defaultTheme,
  pop1: "#ffcd4d",
  pop2: "#fff86e",
  pollMinor: "#3f135f",
  highlight: "#FF7839",
  background1: "#FE5506",
  background2: "#C83100",
  background3: "#A32400",
  gradient1: gradient('#FE5506', '#C83100'), //  background1 to background2
  gradient2: gradient('#A32400', '#C83100'), // background3 to background2
  buttonGradient: gradient('#FF7839', '#FE5506'), // highlight to background1
  text: "white"
};
const red: StoryTheme = {
  ...defaultTheme,
  pop1: "#ffcd4d",
  pop2: "#fff86e",
  pollMinor: "#ffffff",
  highlight: "#FF4E4E",
  background1: "#F51830",
  background2: "#BC0F22",
  background3: "#910817",
  gradient1: gradient('#F51830', '#BC0F22'), //  background1 to background2
  gradient2: gradient('#910817', '#BC0F22'), // background3 to background2
  buttonGradient: gradient('#FF4E4E', '#F51830'), // highlight to background1
  text: "white"
};
const purple: StoryTheme = {
  ...defaultTheme,
  pop1: "#ffcd4d",
  pop2: "#fff86e",
  pollMinor: "#fb6621",
  highlight: "#9645FD",
  background1: "#6B08E8",
  background2: "#4409B4",
  background3: "#190677",
  gradient1: gradient('#6B08E8', '#4409B4'), //  background1 to background2
  gradient2: gradient('#190677', '#4409B4'), // background3 to background2
  buttonGradient: gradient('#9645FD', '#6B08E8'), // highlight to background1
  text: "white"
};
const blue: StoryTheme = {
  ...defaultTheme
};
const lightBlue: StoryTheme = {
  ...defaultTheme,
  pop1: "#ffcd4d",
  pop2: "#fff86e",
  pollMinor: "#2df2ff",
  highlight: "#79DAF4",
  background1: "#53BFEB",
  background2: "#2387B0",
  background3: "#0F6283",
  gradient1: gradient('#53BFEB', '#2387B0'), //  background1 to background2
  gradient2: gradient('#0F6283', '#2387B0'), // background3 to background2
  buttonGradient: gradient('#79DAF4', '#53BFEB'), // highlight to background1
  text: "white"
};

type StoryThemeMap = { [themeId in StoryThemeColor]: StoryTheme };
export const StoryThemes: StoryThemeMap = {
  Turquoise: turquoise,
  Teal: teal,
  Green: green,
  Yellow: yellow,
  Orange: orange,
  Red: red,
  Purple: purple,
  Blue: blue,
  "Light Blue": lightBlue,
  "Light Green": lightGreen
};

export function getStoryTheme(themeColor: StoryThemeColor): StoryTheme {
  if (themeColor) {
    return StoryThemes[themeColor];
  } else {
    return blue;
  }
}

// Return a theme name from a given uid
export const themeNameByUid = (uid: string): StoryThemeColor => {
  const hash = md5(uid);
  const defaultColours = Object.keys(StoryThemes).map(
    key => key as StoryThemeColor
  )
  // Offset colour decimal to avoid issues with 6 colours and 24 icons
  const colourDecimal = parseInt(hash.slice(6, 12), 16);
  const colourIndex = colourDecimal % defaultColours.length;
  return defaultColours[colourIndex]
}

export enum GalleryTheme {
  'SportingNation' = 2,
  'Olympic' = 3,
  'AustralianFootball' = 4,
  'SportsHallOfFame' = 6,
  'Cricket' = 7,
  'HorseRacing' = 9,
}

const SportingNation: StoryTheme = {
  ...defaultTheme,
  background1: "#0b5ada",
  background2: "#0b5ada",
  pop1: '#ffffff',
  line1: "#ffcd4d",
  line2: "#5becdd",
  line3: "#0b5ada",
  progressBar: "#0b5ada",
}
const Olympic: StoryTheme = {
  ...defaultTheme,
  background1: "#fe5506",
  background2: "#fe5506",
  pop1: '#ffffff',
  line1: "#fe5506",
  line2: "#53bfeb",
  line3: "#fe5506",
  progressBar: "#fe5506",
}
const AustralianFootball: StoryTheme = {
  ...defaultTheme,
  background1: "#f51830",
  background2: "#f51830",
  pop1: '#ffffff',
  line1: "#11a4a7",
  line2: "#0b5ada",
  line3: "#f51830",
  progressBar: "#f51830",
}
const SportsHallOfFame: StoryTheme = {
  ...defaultTheme,
  background1: "#373737",
  background2: "#373737",
  pop1: '#ffcd4d',
  line1: "#373737",
  line2: "#ffcd4d",
  line3: "#373737",
  progressBar: "#ffcd4d",
}
const Cricket: StoryTheme = {
  ...defaultTheme,
  background1: "#92c61a",
  background2: "#92c61a",
  pop1: '#ffffff',
  line1: "#92c61a",
  line2: "#ffcd4d",
  line3: "#92c61a",
  progressBar: "#92c61a",
}
const HorseRacing: StoryTheme = {
  ...defaultTheme,
  background1: "#53bfeb",
  background2: "#53bfeb",
  pop1: '#ffffff',
  line1: "#53bfeb",
  line2: "#fe5506",
  line3: "#53bfeb",
  progressBar: "#53bfeb",
}

type GalleryThemeMap = { [GalleryTheme: string]: StoryTheme };
export const GalleryThemes: GalleryThemeMap = {
  SportingNation,
  Olympic,
  AustralianFootball,
  SportsHallOfFame,
  Cricket,
  HorseRacing,
};

export function getGalleryTheme(galleryId: number): StoryTheme {
  if (galleryId) {
    return GalleryThemes[GalleryTheme[galleryId]];
  } else {
    return {...defaultTheme, };
  }
}