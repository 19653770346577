import React from "react";
import styled from "styled-components";
import Result, { ResultProps } from "./Result";
import { StoryTheme } from "../../styles/Themes";

const Container = styled.div`
  width: 50%;
  position:relative;
`;

const ImageContainer =  styled.div<{ percent: number, mask?: string | undefined }>`
  width: 100%;
  height: 100%;
  ${({ mask }) => mask ? `
    mask-image: url(${mask});
    mask-size: 100% 100%;
    mask-clip: border-box;
  `: null }
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: ${({ theme, percent }) => percent >= 0.5 ? theme.background1 : theme.lightGrey};
`

const Progress = styled.div<{ percent: number, theme: StoryTheme }>`
  background-color: ${({ theme, percent }) => percent >= 0.5 ? theme.background1 : theme.lightGrey};
  display: block;
  width: 100%;
  height: 100%;
  clip-path: ${({ percent }) => `inset(0% 0% 0% ${100 - (percent * 100)}%)`};
`

const Image = styled.img`
  object-fit: contain;
  width: 100%;
  height: 100%;
`;

type Props = {
  maskUrl: string;
  imageUrl: string;
} & ResultProps


const ImageWipeResult = ({
  responsePercentLeft,
  responsePercentRight,
  maskUrl,
  imageUrl,
  ...props
}: Props ) => {
  return (
    <Result
      responsePercentLeft={responsePercentLeft}
      responsePercentRight={responsePercentRight}
      {...props}
    >
      <Container>
        <ImageContainer percent={responsePercentLeft} mask={maskUrl}>
          <Progress percent={responsePercentRight}/>
        </ImageContainer>
        <Image src={imageUrl} />
      </Container>
    </Result>
  );
};

export default ImageWipeResult;
