import React from "react";

import AvatarContainer from "./AvatarContainer";

export default (
  <AvatarContainer>
    <path
      fill="inherit"
      d="M48 4c24.3 0 44 19.7 44 44S72.3 92 48 92 4 72.3 4 48 23.7 4 48 4zm0 4C25.909 8 8 25.909 8 48s17.909 40 40 40 40-17.909 40-40S70.091 8 48 8zm0 73c17.673 0 32-14.23 32-31.783 0-7.4-2.562-14.425-7.17-20.05-1.93-2.355-5.418-2.71-7.79-.794a5.473 5.473 0 00-.799 7.736 20.588 20.588 0 014.687 13.108c0 11.48-9.37 20.786-20.928 20.786-11.558 0-20.928-9.306-20.928-20.786 0-4.898 1.705-9.522 4.777-13.218a5.473 5.473 0 00-.746-7.74c-2.358-1.934-5.847-1.603-7.793.74A31.534 31.534 0 0016 49.216C16 66.77 30.327 81 48 81zM26.742 36.991a1.89 1.89 0 11-3.273-1.889 1.89 1.89 0 013.273 1.89zm44.848.693a1.89 1.89 0 11-1.889-3.274 1.89 1.89 0 011.89 3.274zm2.17 6.53a1.89 1.89 0 11-.976-3.653 1.89 1.89 0 01.977 3.653zm-49.456-1.337a1.89 1.89 0 11-3.652-.978 1.89 1.89 0 013.652.978zm-2.721 8.206a1.89 1.89 0 110-3.78 1.89 1.89 0 010 3.78zm52.585 0a1.89 1.89 0 110-3.78 1.89 1.89 0 010 3.78zm.93 5.404a1.89 1.89 0 11-3.652-.98 1.89 1.89 0 013.652.98zm-52.13 1.337a1.89 1.89 0 11-.978-3.65 1.89 1.89 0 01.977 3.65zm3.082 6.152a1.89 1.89 0 11-1.89-3.272 1.89 1.89 0 011.89 3.272zm46.232-.691a1.89 1.89 0 11-3.272-1.89 1.89 1.89 0 013.272 1.89zM30.62 69.12a1.89 1.89 0 11-2.673-2.673 1.89 1.89 0 012.673 2.673zm37.183 0a1.89 1.89 0 11-2.672-2.673 1.89 1.89 0 012.672 2.673zm-31.437 3.787a1.89 1.89 0 11-3.274-1.892 1.89 1.89 0 013.274 1.892zm25.6.692a1.89 1.89 0 11-1.889-3.274 1.89 1.89 0 011.89 3.274zm-6.796 2.815a1.89 1.89 0 11-.98-3.65 1.89 1.89 0 01.98 3.65zM42.896 75.08a1.89 1.89 0 11-3.652-.98 1.89 1.89 0 013.652.98zm4.979 2.296a1.89 1.89 0 110-3.78 1.89 1.89 0 010 3.78z"
    />
  </AvatarContainer>
);
