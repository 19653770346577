import React, { useState, FC } from 'react';
import styled from 'styled-components';
import CopyToClipboard from 'react-copy-to-clipboard';
import { fontSize } from '../styles/Typography';
import { space } from '../styles/Layout';
import { colours } from '../styles/Themes';

const Container = styled.div`
  background: ${colours.white};
  padding: ${space(0.5)} ${space(1)};
  max-width: 100%;
`

const Box = styled.div`
  border: 1px solid #d7d7d7;
  max-width: 100%;
  background: #f9f9f9;
  height: 44px;
  border-radius: 4px;
  display: inline-flex;
  flex-wrap: nowrap;
  font-size: ${fontSize.xsmall};
  letter-spacing: 0.07px;
`

const Text = styled.div`
  padding-left: ${space(1)};
  padding-top: ${space(2)};
  overflow: hidden;
  max-width: calc(100% - 80px);
  position: relative;
  height: 40px;
  &:after {
    content: '';
    width: 10px;
    display: block;
    height: 40px;
    position: absolute;
    top: 2px;
    right: 0;
    bottom: 0;
    background: linear-gradient(to right, rgba(249, 249, 249, 0) -52%, #f9f9f9 82%);
    z-index: 10;
  }
  & > span {
    user-select: text;
    white-space: nowrap;
    padding-right: ${space(1)};
    &::selection {
      color: #fff;
      background: #000;
    }
  }
`

const CopyButton = styled(CopyToClipboard)`
  background: #000;
  color: #fff;
  border-radius: 2px;
  text-align: center;
  height: 38px;
  margin-top: 2px;
  margin-right: 2px;
  justify-content: center;
  width: 80px; 
  display: inline-block;
  margin-left: auto;
  align-items: center;
  display: flex;
  cursor: pointer;
`
type Props = {
  url: string;
}

const SocialUrl: FC<Props> = ({ url }) => {
  const [copyText, setCopyText] = useState<string>('Copy URL');
  return (
    <Container>
      <Box>
        <Text><span>{url}</span></Text>
        <CopyButton text={url} onCopy={() => setCopyText('URL Copied')}>
          <span>{copyText}</span>
        </CopyButton>
      </Box>
    </Container>
  )
}


export default SocialUrl;
