import styled from "styled-components";
import { StoryTheme, colours } from "../../styles/Themes";
import { fontSize, fontFamily } from "../../styles/Typography";
import LockIcon from '../icons/LockIcon';
import { WrapStyles, space, FourColumn } from '../../styles/Layout';

export const Container = styled.div<{ theme: StoryTheme }>`
  color: ${({ theme }) => theme.text };
`

export const Wrap = styled.div`
  ${WrapStyles}
  padding-top: ${space(4)};
  padding-bottom: ${space(6)};
`

export const Thumbnails = styled.ul`
  ${FourColumn}
  list-style: none;
`

export const Photo = styled.a<{ theme: StoryTheme, image: string }>`
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.15);
  background-image: url(${({ image }) => image });
  overflow: hidden;
  padding-top: 75%;
  display: block;
  border: none;
  background-size: cover;
  background-position: center;
  image-rendering: auto;
  background-repeat: no-repeat;
  filter: blur(0px);
  cursor: pointer;
  border-radius: 0;
  transform: translate3d(0,0,0);
  backface-visibility: hidden;
  border: 3px solid #fff;
`
export const Empty = styled.div`
  border: dashed 2px rgba(0, 0, 0, 0.2);
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 12px;
  width: 100%;
  display: block;
  padding-top: 75%;
  position: relative;
  & > div {
    position: absolute;
    top: 40px;
    left: 0;
    right: 0;
  }
  strong {
    color: #fff;
    opacity: 0.5;
    font-size: ${fontSize.small};
    font-family: ${fontFamily.middleWeight};
    letter-spacing: 0.08px;
    text-align: center;
    display: block;
    margin-top: 10px;
    text-transform: uppercase;
    padding-left: ${space(0.5)};
    padding-right: ${space(0.5)};
  }
`

export const Lock = styled(LockIcon)`
  margin: 0 auto;
  display: block;
  opacity: 0.5;
  path {
    fill: ${colours.white}};
  }
`

export const Souvenir = styled.div`
  background: ${colours.white};
  padding-left: ${space(1)};
  padding-right: ${space(1)};
  padding-top: ${space(7)};
  padding-bottom: ${space(2)}
  width: 100%;
  & > * {
    margin-bottom: ${space(2)}
  }
  img {
    max-width: 100%;
    width: 100%;
  }
`

export const Share = styled.div`
  display: flex;
  justify-content: center;
  margin-top: ${space(1)};
`