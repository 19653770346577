import styled, { css } from 'styled-components';

export const MAX_WIDTH = '768px';
const SPACE = 8;

export const space = (multiplier: number, unit: number = SPACE): string => `${unit * multiplier}px`;

export const breakPoints = {
  small: 600,
  medium: 768,
  large: 1024,
}

export const mediaQuery = (minWidth: number): string => `@media only screen and (min-width: ${minWidth}px)`

export const media = {
  custom: mediaQuery,
  small: mediaQuery(breakPoints.small),
  medium: mediaQuery(breakPoints.medium),
  large: mediaQuery(breakPoints.large)
};

export const WrapStyles = css`
  padding-left: ${space(1)};
  padding-right: ${space(1)};
  margin: 0 auto;
  max-width: ${MAX_WIDTH};
`

export const Wrap = styled.div`
  ${WrapStyles}
`

export const SectionHeader = styled.div`
  display: flex;
  margin-bottom: ${space(2)};
  & > *:last-child {
    margin-left: auto;
  }
`

export const TwoColumn = css`
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: ${space(1)};
  grid-row-gap: ${space(1)};
  ${media.small} {
    grid-template-columns: 1fr 1fr;
  }
`

export const FourColumn = css`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: ${space(1)};
  grid-row-gap: ${space(1)};
  ${media.small} {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
`

export const Footer = styled.div`
  padding-top: ${space(6)};
  padding-bottom: ${space(6)};
  display: flex;
  align-items: center;
  flex-direction: column;
  ${WrapStyles}
  & > * {
    margin-bottom: ${space(2)};
  }
`
