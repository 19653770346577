import React, { useState } from 'react';
import DatePicker, { ReactDatePickerProps }  from "react-datepicker";
import styled from "styled-components";
import { fontFamily, fontSize } from '../styles/Typography';
import { space } from '../styles/Layout';
import { colours } from '../styles/Themes';
import moment from 'moment';

const DateInputWrap = styled.div`
  position: relative;
  width: 300px;
  margin: auto;
  
  .react-datepicker-wrapper {
    display: block;
    padding: 0;
    border: 0;
    width: 100%;
    width: 300px;
    margin: 0 auto ${space(2)};

    input {
      border: 2px solid ${colours.black35};
      width: 300px;
      padding: ${space(1)} ${space(2)};
      border-radius: 8px;
      background: ${colours.white};
      color: ${colours.darkGrey};
      font-size: ${fontSize.medium};
      font-family: ${fontFamily.middleWeight};
      height: 56px;
      &:focus {
        border: 2px solid ${colours.black65};
        outline: none;
      }
    }
  }

  .react-datepicker__input-container {
    position: relative;
    display: block;
    width: 100%;
  }

  .react-datepicker {
    font-family: ${fontFamily.juniorWelterWeight};
    font-size: ${fontSize.medium};
    background-color: ${colours.white};
    color: ${colours.darkGrey};
    border: 1px solid ${colours.lightGrey};
    border-radius: 4px;
    display: inline-block;
    position: relative;
    width: 100%;
    max-width: 300px;
  }

  .react-datepicker-popper {
    width: 100%;
    position: relative !important;
    transform: none !important;
  }

  .react-datepicker__current-month {
    margin-top: 0;
    color: ${colours.darkGrey};
    font-size: ${fontSize.medium};
    margin-bottom: ${space(1)};
  }

  .react-datepicker__month-container {
    width: 100%;
    padding: ${space(1)};
  }

  .react-datepicker__month {
    border-right: 1px solid ${colours.lightGrey};
    border-top: 1px solid ${colours.lightGrey};
    text-align: center;
  }
  
  .react-datepicker__day-names,
  .react-datepicker__week {
    white-space: nowrap;
    width: 100%;
    display: flex;
    flex-direction: row;
  }

  .react-datepicker__day-name {
    color: ${colours.grey};
    margin-top: ${space(1)};
    padding: ${space(1)};
    display: inline-block;
    width: calc(100% / 7);
    height: calc(100% / 7);
    text-align: center;
    font-size: ${fontSize.small};
  }

  .react-datepicker__day {
    font-size: ${fontSize.small};
    color: ${colours.darkGrey};
    display: block;
    padding: 8px;
    width: calc(100% / 7);
    height: calc(100% / 7);
    text-align: center;
    border-left: 1px solid ${colours.lightGrey};
    border-bottom: 1px solid ${colours.lightGrey};
    
    &--keyboard-selected,
    &:hover {
      background-color: ${colours.lightGrey};
    }
    &--disabled {
      color: ${colours.lightGrey};
      cursor: none;
      &:hover {
        background-color: transparent;
      }
    }
  }
`

const CalendarButton = styled.button`
  position: absolute;
  top: 4px;
  right: 4px;
  z-index: 10;
  width: 48px;
  height: 48px;
  border: none;
  background: #f5f5f5;
  border-radius: 0 4px 4px 0;
  text-align: center;
  path {
    fill: ${colours.darkGrey};
  }
`

const Arrow = styled.button<{ isPrev: boolean }>`
  background-color: #f5f5f5;
  border: none;
  text-align: center;
  cursor: pointer;
  position: absolute;
  top: 8px;
  padding: 0;
  z-index: 1;
  height: 44px;
  width: 44px;
  overflow: hidden;
  border-radius: 4px;
  display: block;
  text-align: center
  ${({ isPrev }) => isPrev ? 'left: 0;' : 'right: 0;' }
`

const Header = styled.div`
  text-align: center;
  padding-top: ${space(2)};
  padding-bottom: ${space(2)};
  position: relative;
`

const DateInput = (props: ReactDatePickerProps) => {
  const [open, setOpen] = useState<boolean>(false)
  const openHandler = (evt: React.MouseEvent) => {
    setOpen(true)
    evt.currentTarget.scrollIntoView();
  }
  const changeHandler = (handler: ReactDatePickerProps["onSelect"] | ReactDatePickerProps["onChange"]) => (date: Date, event: React.SyntheticEvent<any, Event> | undefined): void => {
    if(handler) handler(date, event)
    setOpen(false)
  }
  return (
    <DateInputWrap>
      <CalendarButton type="button" onClick={openHandler}>
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"><path fill="#101010" fill-rule="evenodd" d="M17 0a2 2 0 012 2h1a4 4 0 014 4v14a4 4 0 01-4 4H4a4 4 0 01-4-4V6a4 4 0 014-4h1a2 2 0 114 0h6a2 2 0 012-2zm4 9H3v10a2 2 0 001.85 1.995L5 21h14a2 2 0 001.995-1.85L21 19V9z"/></svg>
      </CalendarButton>
      <DatePicker
        {...props}
        showPopperArrow={false}
        maxDate={new Date()}
        renderCustomHeader={({
          date,
          decreaseMonth,
          increaseMonth,
          prevMonthButtonDisabled,
          nextMonthButtonDisabled
        }) => (
          <Header>
            <Arrow type="button" isPrev={true} onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="16"><path fill="#0F0F0F" fill-rule="evenodd" d="M9.972.282L.921 6.542a1.607 1.607 0 00-.52.392l-.109.138a1.579 1.579 0 00-.29.981c.005.248.07.497.196.726l.097.154c.166.234.383.41.626.526l9.051 6.259c.686.474 1.61.346 2.144-.273l.099-.126a1.586 1.586 0 00-.256-2.117l-.136-.105-5.059-3.5h11.638a1.598 1.598 0 100-3.195H6.764l5.06-3.5a1.586 1.586 0 00.482-2.076l-.094-.149a1.617 1.617 0 00-2.24-.395z"/></svg>
            </Arrow>
            {moment(date).format('MMMM YYYY')}
            <Arrow type="button" isPrev={false} onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="16"><path fill="#0F0F0F" fill-rule="evenodd" d="M10.028.282l9.051 6.26c.194.091.372.223.52.392l.109.138c.211.297.305.643.29.981-.005.248-.07.497-.196.726l-.097.154a1.61 1.61 0 01-.626.526l-9.051 6.259a1.617 1.617 0 01-2.144-.273l-.099-.126a1.586 1.586 0 01.256-2.117l.136-.105 5.059-3.5H1.598a1.598 1.598 0 110-3.195h11.638l-5.06-3.5A1.586 1.586 0 017.695.827l.094-.149a1.617 1.617 0 012.24-.395z"/></svg>
            </Arrow>
          </Header>
        )}
        
        selected={props.selected}
        onSelect={changeHandler(props.onSelect)}
        onChange={changeHandler(props.onChange)}
        open={open}
      />
    </DateInputWrap>
  )
}

export default DateInput
