import React, { useState, useEffect } from "react";
import { GroupedPoll } from "../helpers/polls";
import styled from 'styled-components';
import { space } from '../styles/Layout';
import { SlimHeading } from '../styles/Typography';
import { colours } from '../styles/Themes';
import {
  UserDataClient,
  PollSummary,
  PublicProfileResponse
} from "../userData";
import { Collection } from "../contentfulModels";
import { GalleryEntry, PollEntry } from "../contentfulModels";
import { groupPollData } from "../helpers/polls";
import {
  groupPollsByGallery,
} from "../helpers/contentful";

// background.
import dayAtTheRacesBackground from "../images/day-at-the-races-background.png";
import youMakeTheRulesBackground from "../images/you-make-the-rules-background.jpg";

// components.
import Spinner from "../components/Spinner";
import VideoPlayer from '../components/VideoPlayer';
import ImageGenerator from '../components/ImageGenerator';
import { makePollResult, GalleryHeader } from "../components/Gallery/Gallery";
import Logo from "../components/Logo";

// themes.
import { StoryTheme, getGalleryTheme } from '../styles/Themes';
import { ThemeProvider} from "styled-components";


const Loading = styled.div<{ theme: StoryTheme }>`
  background: ${({ theme }) => theme.gradient2 };
  height: 100vh;
  display: flex;
  & > * {
    margin: auto;
    opacity: 0.7;
  }
  svg {
    width: 80px;
    height: 80px;
  }
`

const Container = styled.div`
  background: #fff;
  margin: 0 auto;
  height:100vh;
  background: linear-gradient(45deg, #0b5ada, #11a4a7, #078f40, #92c61a, #ffcd4d, #fe5506, #f51830, #0b5ada);
  background-size: 1600% 1600%;
  -webkit-animation: AnimationName 80s ease infinite;
  -moz-animation: AnimationName 80s ease infinite;
  animation: AnimationName 80s ease infinite;
  @-webkit-keyframes AnimationName {
      0%{background-position:94% 0%}
      50%{background-position:7% 100%}
      100%{background-position:94% 0%}
  }
  @-moz-keyframes AnimationName {
      0%{background-position:94% 0%}
      50%{background-position:7% 100%}
      100%{background-position:94% 0%}
  }
  @keyframes AnimationName { 
      0%{background-position:94% 0%}
      50%{background-position:7% 100%}
      100%{background-position:94% 0%}
  }
  display: block;
`

export const View = styled.div`
  background: ${colours.white};
  padding-left: ${space(1)};
  padding-right: ${space(1)};
  padding-top: ${space(2)};
  padding-bottom: ${space(2)}
  width: 100%;
  & > * {
    margin-bottom: ${space(2)}
  }
  img {
    max-width: 100%;
    width: 100%;
  }
`

export const Wrap = styled.div`
  margin: 0 auto;
  position: fixed;
  max-width: 768px;
  top: 50%;
  width: 100%;
  left: 50%;
  transform: translate(-50%, -50%);
`


export const VIEW_TYPES = {
  HEAD_IN_THE_CROWDS: 'head-in-the-crowds',
  YOU_MAKE_THE_RULES: 'you-make-the-rules',
  CLASSIC_CATCH: 'classic-catch',
  DAY_AT_THE_RACES: 'day-at-the-races',
  POLL_RESULT: 'poll-result',
}

type ViewTypes = typeof VIEW_TYPES
type ViewType = ViewTypes[keyof ViewTypes]

const getView = ({ viewType, publicProfile, youMakeTheRulesImage, dayAtTheRacesImage, optional, galleryResults, contentfulCollection }: {
  viewType: ViewType;
  publicProfile: PublicProfileResponse;
  youMakeTheRulesImage: string | null;
  dayAtTheRacesImage: string | null;
  optional?: number | string | null;
  galleryResults?: Array<{ gallery:GalleryEntry; pollData: GroupedPoll[]}>;
  contentfulCollection: Collection;
}) => {
  switch(viewType) {
    case VIEW_TYPES.HEAD_IN_THE_CROWDS :
      const photoNumber = Number(optional)
      return publicProfile.head_in_the_crowds_photos && photoNumber && publicProfile.head_in_the_crowds_photos[photoNumber - 1] ? (
        <View>
          <img src={publicProfile.head_in_the_crowds_photos[photoNumber - 1].photo_url} alt="Head in the crowds" />
          <SlimHeading>Head in the crowds</SlimHeading>
        </View>
      ): null
    case VIEW_TYPES.YOU_MAKE_THE_RULES : 
      return youMakeTheRulesImage ? (
        <View>
          <img src={youMakeTheRulesImage} alt="You make the rules"/>
          <SlimHeading>You make the rules</SlimHeading>
        </View>
      ): null
    case VIEW_TYPES.CLASSIC_CATCH :
      return publicProfile.game_on.classic_catch_video_url ? (
        <View>
          <VideoPlayer url={publicProfile.game_on.classic_catch_video_url} />
          <SlimHeading>Classic Catch</SlimHeading>
        </View>
      ): null
    case VIEW_TYPES.DAY_AT_THE_RACES:
      return dayAtTheRacesImage ? (
        <View>
          <img src={dayAtTheRacesImage} alt="Day at the races"/>
          <SlimHeading>Day at the races</SlimHeading>
        </View>
      ) : null
    case VIEW_TYPES.POLL_RESULT:
      if (optional) {
        const arr = String(optional).split('|')
        const [galleryId, pollId] = arr
        if(!galleryId || !pollId || !galleryResults) return null
        const gallery = galleryResults
          .filter(({ gallery }) => gallery.fields.id === Number(galleryId))[0]
        const result = gallery.pollData.filter(({ poll }) => poll.sys.id === pollId)[0]
        return (
          <ThemeProvider
            theme={getGalleryTheme(Number(galleryId))}
          >
            <View>
              {gallery.gallery.fields.name && <GalleryHeader title={gallery.gallery.fields.name}/>}
              {makePollResult({ data: result, contentfulCollection})}
            </View>
          </ThemeProvider>
        )
      }
      return null
    default: 
      return null
  }
}

type Props = {
  path: string;
  contentfulCollection: Collection | "loading" | "error";
  userDataClient: UserDataClient;
  publicId?: string;
  viewType?: ViewType;
  '*'?: string;
};

export function SharePage(props: Props) {
  const {
    contentfulCollection,
    userDataClient,
    publicId,
    viewType
  } = props
  const [publicProfile, setPublicProfile] = useState<PublicProfileResponse | 'loading' | 'error'>('loading')
  const [pollResults, setPollResults] = useState<PollSummary[]>([]);
  const [youMakeTheRulesImage, setYouMakeTheRulesImage] = useState<string | null>(null)
  const [dayAtTheRacesImage, setDayAtTheRacesImage] = useState<string | null>(null)

  useEffect(() => {
    async function loadData() {
      setPublicProfile('loading');
      if(!publicId) {
        setPublicProfile('error');
        return
      }
      const publicProfile = await userDataClient.publicProfile({ publicId });
      setPublicProfile(publicProfile);
      const summaries = await userDataClient.pollSummaries();
      setPollResults(summaries);
    }
    if (contentfulCollection !== 'error' && contentfulCollection !== 'loading') loadData();
  }, [userDataClient, contentfulCollection, publicId]);

  if (contentfulCollection === 'error' || publicProfile === 'error') return (
    <Container>
      <Loading>
        Error Details here.
      </Loading>
    </Container>
  )
  if (contentfulCollection === 'loading' || publicProfile === 'loading') return (
    <Container>
      <Loading>
        <Spinner />
      </Loading>
    </Container>
  )

  const galleries: GalleryEntry[] = Array.from(
    contentfulCollection.galleries.values()
  );

  const pollsByGallery = groupPollsByGallery(contentfulCollection);
  const galleryResults = galleries
    .map((gallery: GalleryEntry) => ({
      gallery,
      pollData: groupPollData({
        polls: pollsByGallery.get(gallery) as PollEntry[],
        results: pollResults,
        profileResults: publicProfile.polls
      }),
    }))

  const view = !!viewType
    ? getView({
        viewType,
        publicProfile,
        dayAtTheRacesImage,
        youMakeTheRulesImage,
        optional: props['*'],
        galleryResults,
        contentfulCollection,
      })
    : null
  return (
    <div>
        <Container>
          <Wrap>
            <Logo/>
            {view}
          </Wrap>
        </Container>
        {publicProfile.day_at_the_races &&
          <ImageGenerator
            backgroundUrl={dayAtTheRacesBackground}
            imageDetails={{
              url: publicProfile.day_at_the_races.horse_image_url,
              pos: { x: 715, y: 190, scale: 0.33 },
            }}
            withFont={true}
            storeImage={setDayAtTheRacesImage}
            text={{
              value: publicProfile.day_at_the_races.horse_name.toUpperCase(),
              pos: { x: 1100, y: 190 }
            }}
          />}

        {publicProfile.avatar_url && publicProfile.game_on.you_make_the_rules_visited &&
        <ImageGenerator
          backgroundUrl={youMakeTheRulesBackground}
          imageDetails={{
            url: publicProfile.avatar_url.replace("v8.0.0/512x512", "ymtr-avatars"),
            pos: { x: 242, y: 360 }
          }}
          storeImage={setYouMakeTheRulesImage}
        />}
    </div>
  );
}
