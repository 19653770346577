import React from 'react';
import styled from "styled-components";
import { space, media } from "../../styles/Layout";
import { fontSize, fontFamily} from '../../styles/Typography'
import { StoryTheme } from '../../styles/Themes';
import LockIcon from '../icons/LockIcon';

const Wrap = styled.div`
  padding-left: ${space(0.5)};
  padding-right: ${space(0.5)};
  height: 100%;
`
const Empty = styled.div<{ theme: StoryTheme }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  display: flex;
  position: relative;
  min-height: 220px;
  overflow: hidden;
  border-radius: 8px;
  border: 2px dashed #ebebeb;
  padding: ${space(2)} ${space(1)};
  color: ${({ theme }) => theme.lightGrey};
  height: 100%;
  justify-content: center;

  ${media.small} {
    padding: ${space(2)} ${space(5)};
  }
`;

const Lock = styled(LockIcon)`
  margin: 0 auto ${space(3)};
  display: block;
  opacity: 0.1;
  path {
    fill: ${({ theme }) => theme.background1};
  }
`

const Text = styled.strong`
  text-transform: uppercase;
  font-size: ${fontSize.small};
  letter-spacing: 0.08px;
  text-align: center;
  display: block;
  font-family: ${fontFamily.middleWeight};
`
const EmptyResult = () => {
  return (
    <Wrap>
      <Empty>
        <Lock size={'xLarge'}/>
        <Text>Find me in the Museum!</Text>
      </Empty>
    </Wrap>

  )
}

export default EmptyResult
