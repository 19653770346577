import React, { useContext } from "react";
import styled, { ThemeContext } from "styled-components";
import { animated, useSpring } from "react-spring";
import Result, { ResultProps, getColors } from "./Result";

const ARC_CIRCUMFERENCE = 684; // pi * (562 / 2)

type ArcProps = {
  percentDone: number;
  color: string;
};

const Arc = ({ percentDone, color }: ArcProps) => {
  const offsetProps = useSpring({
    strokeDashoffset: (1 - percentDone) * ARC_CIRCUMFERENCE
  });
  return (
    <svg
      viewBox="0 0 586 293"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Artboard"
          transform="translate(-13.000000, -122.000000)"
          stroke={color}
          strokeWidth="150"
        >
          <animated.path
            d="M88,415 C88,286.728985 191.950287,197 306,197 C420.049713,197 524,286.728985 524,415"
            strokeDasharray={ARC_CIRCUMFERENCE}
            style={offsetProps}
          ></animated.path>
        </g>
      </g>
    </svg>
  );
};

const ArcContainer = styled.div`
  position: relative;
  width: 50%;
  svg:last-child {
    position: absolute;
    top: 0;
    left: 0;
  }
`;

const ArcResult = ({
  responsePercentLeft,
  responsePercentRight,
  ...props
}: ResultProps) => {
  const theme = useContext(ThemeContext);
  const colors = getColors(responsePercentLeft, responsePercentRight, theme.background1)
  return (
    <Result
      responsePercentLeft={responsePercentLeft}
      responsePercentRight={responsePercentRight}
      {...props}
    >
      <ArcContainer>
        <Arc color={colors.percent2Color} percentDone={1} />
        <Arc color={colors.percent1Color} percentDone={responsePercentLeft} />
      </ArcContainer>
    </Result>
  );
};

export default ArcResult;
