import React from "react";

import AvatarContainer from "./AvatarContainer";

export default (
  <AvatarContainer>
    <path
      fill="inherit"
      d="M48 4c24.3 0 44 19.7 44 44S72.3 92 48 92 4 72.3 4 48 23.7 4 48 4zm0 4C25.909 8 8 25.909 8 48s17.909 40 40 40 40-17.909 40-40S70.091 8 48 8zM17.395 57.822L40.108 35.18c5.036-5.02 12.937-6.028 18.767-2.323L45.436 46.351a3.04 3.04 0 00.016 4.31 3.064 3.064 0 004.323-.016l13.447-13.5c3.705 5.606 3.157 13.184-1.643 18.208l-.256.262L38.26 78.607a4.785 4.785 0 01-6.578.163l-.173-.163-14.114-14.07a4.738 4.738 0 01-.164-6.544l.164-.171L40.108 35.18 17.395 57.822zm29.017-10.505l26.713-26.822a1.685 1.685 0 012.378-.008c.62.614.66 1.59.117 2.25l-.108.12L48.799 49.68a1.685 1.685 0 01-2.378.008 1.673 1.673 0 01-.117-2.25l.108-.12 26.713-26.822-26.713 26.822z"
    />
  </AvatarContainer>
);
