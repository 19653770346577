import React, { Component, ReactNode, Fragment } from 'react'
import ReactDOM from 'react-dom'
import { Overlay, Container, CloseButton } from './Modal.styles';

import CloseIcon from '../icons/CloseIcon' 

// util.
const canUseDOM = () =>
  !!(typeof window !== 'undefined' && window.document && window.document.createElement)

// id of <div> to act as Portal target
const MODAL_ROOT_ID = 'modal-root'

type ModalProps = {
  children: ReactNode;
  isOpen?: boolean;
  onClose: () => void;
  onAfterOpen?: () => void;
}

class Modal extends Component<ModalProps> {
  static defaultProps = {
    disablePadding: false,
    isOpen: false,
    onAfterOpen: null,
    shouldOverlayClose: false,
    showCloseButton: true,
    smallMode: false,
  }

  // Create Portal target node and append to document.
  // Handy for test environments.
  static createModalRoot(): Element | null {
    if (!canUseDOM()) {
      return null
    }
    const root: Element = document.createElement('div')
    root.setAttribute('id', MODAL_ROOT_ID)
    if (document.body) {
      document.body.appendChild(root)
    }
    return root
  }

  componentDidMount() {
    const { isOpen } = this.props
    if (isOpen) {
      this.open()
    }
  }

  componentDidUpdate(prevProps: ModalProps) {
    const { isOpen } = this.props
    const justOpened = isOpen && !prevProps.isOpen
    if (justOpened) {
      this.open()
    }
  }

  open = (): void => {
    const { onAfterOpen } = this.props
    if (onAfterOpen) {
      onAfterOpen()
    }
  }

  createModal = (props: ModalProps) => {
    const {
      children,
      onClose,
    } = props
    return (
      <Fragment>
        <Overlay onClick={onClose} />
        <Container aria-modal="true" role="dialog">   
          <CloseButton onClick={onClose} ><CloseIcon size='large' /></CloseButton>
          {children}
        </Container>
      </Fragment>
    )
  }

  render() {
    const { isOpen } = this.props

    // Don't render when DOM unavailable
    if (!canUseDOM()) {
      return null
    }

    // Query and assign Portal target node, create one if necessary
    const modalRoot: Element | null = document.getElementById(MODAL_ROOT_ID)
      ? document.getElementById(MODAL_ROOT_ID)
      : Modal.createModalRoot()

    return isOpen && modalRoot !== null
      ? ReactDOM.createPortal(this.createModal(this.props), modalRoot)
      : null
  }
}

export default Modal
