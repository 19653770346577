import React from "react";

import AvatarContainer from "./AvatarContainer";

export default (
  <AvatarContainer>
    <path
      fill="inherit"
      d="M48 4c24.3 0 44 19.7 44 44S72.3 92 48 92 4 72.3 4 48 23.7 4 48 4zm0 4C25.909 8 8 25.909 8 48s17.909 40 40 40 40-17.909 40-40S70.091 8 48 8zm8.856 48.785a30.928 30.928 0 0122.154-9.077l.61.01c.016 8.156-3.09 16.318-9.317 22.54-6.218 6.213-14.37 9.316-22.519 9.31l-.003-.116a30.883 30.883 0 019.075-22.667zm13.447-31.476a31.657 31.657 0 019.286 21.084l-.035-.001a32.259 32.259 0 00-23.635 9.456 32.203 32.203 0 00-9.471 23.158l.01.533a31.708 31.708 0 01-21.142-9.28 31.655 31.655 0 01-9.267-20.706l.449.015a32.262 32.262 0 0023.674-9.455 32.203 32.203 0 009.463-23.616l-.014-.446a31.703 31.703 0 0120.682 9.258zm-21.29-9.286l-.718.028.015.495a30.88 30.88 0 01-9.076 22.63 30.936 30.936 0 01-22.685 9.069l-.496-.016-.03.755c-.321-8.53 2.777-17.163 9.293-23.675 6.517-6.512 15.16-9.607 23.696-9.286z"
    />
  </AvatarContainer>
);
