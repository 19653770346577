import React, { useMemo } from "react";
import styled from 'styled-components';
import md5 from "md5";
import AFLAvatar from "./avatars/AFLAvatar";
import BaseballAvatar from "./avatars/BaseballAvatar";
import BasketballAvatar from "./avatars/BasketballAvatar";
import CricketAvatar from "./avatars/CricketAvatar";
import GloveAvatar from "./avatars/GloveAvatar";
import GolfAvatar from "./avatars/GolfAvatar";
import HelmetAvatar from "./avatars/HelmetAvatar";
import HockeyAvatar from "./avatars/HockeyAvatar";
import HorseshoeAvatar from "./avatars/HorseshoeAvatar";
import KickboxingAvatar from "./avatars/KickboxingAvatar";
import NetballAvatar from "./avatars/NetballAvatar";
import OarAvatar from "./avatars/OarAvatar";
import PaddleAvatar from "./avatars/PaddleAvatar";
import RugbyAvatar from "./avatars/RugbyAvatar";
import ShoeAvatar from "./avatars/ShoeAvatar";
import SoccerAvatar from "./avatars/SoccerAvatar";
import SurfboardAvatar from "./avatars/SurfboardAvatar";
import SwimmerAvatar from "./avatars/SwimmerAvatar";
import TennisAvatar from "./avatars/TennisAvatar";
import VolleyballAvatar from "./avatars/VolleyballAvatar";
import { StoryThemes } from "../styles/Themes";

const Avatars = [
  AFLAvatar,
  BaseballAvatar,
  BasketballAvatar,
  CricketAvatar,
  GloveAvatar,
  GolfAvatar,
  HelmetAvatar,
  HockeyAvatar,
  HorseshoeAvatar,
  KickboxingAvatar,
  NetballAvatar,
  OarAvatar,
  PaddleAvatar,
  RugbyAvatar,
  ShoeAvatar,
  SoccerAvatar,
  SurfboardAvatar,
  SwimmerAvatar,
  TennisAvatar,
  VolleyballAvatar
];

const AvatarImage = styled.div<{ background?: string }>`
  position: relative;
  width: 128px;
  height: 128px;
  margin: 0 auto;
  ${({ background }) => background ?
    `&:before {
      content: "";
      border: 6px solid #fff;
      border-radius: 50%;
      width: 88px;
      height: 88px;
      display: block;
      position: absolute;
      margin: auto;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background: ${background};
    }` : null
  }
  & > * {
    bottom: 0;
    top: 0;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
  }
  img {
    width: 128px;
    position: relative;
    z-index: 10;
  }
`

const defaultColours = Object.values(StoryThemes).map(
  theme => theme.background1
);

type Props = {
  uid: string;
  avatarUrl: string | null;
};

const Avatar = ({ uid, avatarUrl, ...rest }: Props) => {
  const DefaultAvatar = useMemo(() => {
    // Get the hash of the UI in hexadecimal
    // Then get the first 6 digits as decimal integer
    // Then map to a index of an avatar
    const hash = md5(uid);
    const avatarDecimal = parseInt(hash.slice(0, 6), 16);
    // Offset colour decimal to avoid issues with 6 colours and 24 icons
    const colourDecimal = parseInt(hash.slice(6, 12), 16);
    const avatarIndex = avatarDecimal % Avatars.length;
    const colourIndex = colourDecimal % defaultColours.length;
    return (
      <div {...rest} style={{ fill: defaultColours[colourIndex] }}>
        {avatarUrl 
          ? <AvatarImage background={defaultColours[colourIndex]}> <img src={avatarUrl} alt=""/></AvatarImage>
          : <AvatarImage>{Avatars[avatarIndex]}</AvatarImage>
        }
      </div>
    );
  }, [uid, avatarUrl, rest]);

  return DefaultAvatar;
};

export default Avatar;
