import React, { useState, useEffect, Fragment } from "react";
import { Session } from "../helpers/session";
import { GroupedPoll } from "../helpers/polls";
import styled from 'styled-components';
import { Wrap, space, Footer } from '../styles/Layout';
import {
  UserDataClient,
  PollSummary,
  ProfileResponse,
  percentSpecialFeaturesCompleted
} from "../userData";
import { Collection } from "../contentfulModels";
import { GalleryEntry, PollEntry, QuizEntry } from "../contentfulModels";
import { groupPollData } from "../helpers/polls";
import { groupQuizData } from "../helpers/quizzes";
import {
  groupPollsByGallery,
  groupQuizzesByGallery
} from "../helpers/contentful";

// components.
import VistorSummary from "../components/VistorSummary";
import Gallery from "../components/Gallery";
import Souvenirs from "../components/Souvenirs";
import GameOn from "../components/GameOn";
import AngleHeading from '../components/AngleHeading';
import Spinner from "../components/Spinner";

import { getStoryTheme, themeNameByUid, StoryTheme } from '../styles/Themes';
import { ThemeProvider } from "styled-components";
import Button from "../components/Button";
import { navigate } from "@reach/router";
import { fontFamily, fontSize } from "../styles/Typography";
import NewsletterForm from "../components/NewsletterForm";

// TODO: REMOVE WHEN WE HAVE DATA
// import WIPE_BG from "../images/wipe-01-bg.png";
// import WIPE_MASK from "../images/wipe-01-mask.png";

const Background = styled.div<{ theme: StoryTheme }>`
  background: ${({ theme }) => theme.background2 };
`

const Loading = styled.div<{ theme: StoryTheme }>`
  background: #E6E6E6;
  height: 100vh;
  display: flex;
  & > * {
    margin: auto;
    opacity: 0.7;
  }
  svg {
    width: 80px;
    height: 80px;
  }
`

const Container = styled.div`
  background: #fff;
  margin: 0 auto;
  max-width: 1024px;
`

const Galleries = styled.div`
  padding-top: ${space(7)};
  padding-bottom: ${space(4)};
`

const Logout = styled.button`
  font-family: ${fontFamily.juniorWelterWeight};
  font-size: ${fontSize.medium};
  text-transform: 'uppercase';
  border: 0;
  background: none;
  color: #000;
  cursor: pointer;
`


const getPollsAnswered = (polls:GroupedPoll[]) => (
  Math.round(polls.filter(poll => poll.profileResult && poll.profileResult.vote !== null).length / polls.length * 100)
)

type Props = {
  path: string;
  session: Session;
  contentfulCollection: Collection | "loading" | "error";
  userDataClient: UserDataClient;
  onLogout: () => void;
};

export function ProfilePage({
  session,
  contentfulCollection,
  userDataClient,
  onLogout
}: Props) {


  const [profile, setProfile] = useState<ProfileResponse | 'loading' | 'error'>('loading')
  const [pollResults, setPollResults] = useState<PollSummary[]>([]);

  const onNicknameUpdated = (newNickname: string) => {
    userDataClient.updateNickname({privateId: session.privateId, nickname: newNickname});
    setProfile((profile) => {
      // Profile will have always loaded by this point
      let profileResp = profile as ProfileResponse;
      return {...profileResp, nickname: newNickname}
    });
  }

  useEffect(() => {
    async function loadData() {
      setProfile('loading');
      const summaries = await userDataClient.pollSummaries();
      const profileData = await userDataClient.profile({
        privateId: session.privateId
      });
      setProfile(profileData);
      setPollResults(summaries);
    }
    if (contentfulCollection !== 'error' && contentfulCollection !== 'loading') loadData();
  }, [userDataClient, session, contentfulCollection]);

  if (contentfulCollection === 'error' || profile === 'error') return (
    <ThemeProvider
      theme={getStoryTheme(themeNameByUid('virtual_card'))}
    >
      <Loading>
        Error Details here.
      </Loading>
    </ThemeProvider>
    )
  if (contentfulCollection === 'loading' || profile === 'loading') return (
    <ThemeProvider
      theme={getStoryTheme(themeNameByUid('virtual_card'))}
    >
      <Loading>
        <Spinner />
      </Loading>
    </ThemeProvider>
    )

  const galleries: GalleryEntry[] = Array.from(
    contentfulCollection.galleries.values()
  );

  const pollsByGallery = groupPollsByGallery(contentfulCollection);
  const quizzesByGallery = groupQuizzesByGallery(contentfulCollection);
 
  const galleriesProgress =  (galleries: GalleryEntry[]): Array<{
    id: number | undefined;
    percentage: number;
  }> => {
    return galleries.map(gallery => {
      const polls = groupPollData({
        polls: pollsByGallery.get(gallery) as PollEntry[],
        results: pollResults,
        profileResults: profile.polls
      })
      return {
        id: gallery.fields.id,
        percentage: getPollsAnswered(polls)
      }
    })
  }
  return (
    <div>
      <ThemeProvider
        theme={getStoryTheme(themeNameByUid(profile.token_id))}
      >
        <Fragment>
          <VistorSummary
            profile={profile}
            totalCovered={Math.round(percentSpecialFeaturesCompleted(profile))}
            avatar={profile.avatar_url}
            galleriesProgress={galleriesProgress(galleries)}
            onNicknameUpdated={onNicknameUpdated}
          >
            <Souvenirs
              dayAtTheRacesProfile={profile.day_at_the_races}
              headInTheCrowds={profile.head_in_the_crowds_photos}
              youMakeTheRules={profile.game_on.you_make_the_rules_visited}
              classicCatchVideoUrl={profile.game_on.classic_catch_video_url}
              avatar={profile.avatar_url}
              publicId={profile.public_id}
            />
          </VistorSummary>
          
          <GameOn scores={profile.game_on}/>
          
          <Background>
            <Container>
              <Fragment>
                <Wrap>
                  <AngleHeading>Polls &amp; Trivia</AngleHeading>
                </Wrap>
                <Galleries id="results">
                {
                  galleries
                  .map(gallery => ({
                    gallery,
                    pollData: groupPollData({
                      polls: pollsByGallery.get(gallery) as PollEntry[],
                      results: pollResults,
                      profileResults: profile.polls
                    }),
                  }))
                  .map(gallery => ({
                    ...gallery,
                    percentage: getPollsAnswered(gallery.pollData)
                  }))
                  .sort((a: any, b: any) => b.percentage - a.percentage)
                  .map(({ gallery }) => (
                    <Gallery
                      key={gallery.sys.id}
                      publicId={profile.public_id}
                      gallery={gallery}
                      contentfulCollection={contentfulCollection}
                      polls={groupPollData({
                        polls: pollsByGallery.get(gallery) as PollEntry[],
                        results: pollResults,
                        profileResults: profile.polls
                      })}
                      quizzes={groupQuizData({
                        quizzes: quizzesByGallery.get(gallery) as QuizEntry[],
                        results: profile.quizzes
                      })}
                    />
                  ))
                }
              </Galleries>
              </Fragment>
              <Footer>
                {profile.group_id && <Button onClick={() => { navigate('/polls')}}>Group poll results</Button>}
                <Logout onClick={onLogout}>Logout</Logout>
              </Footer>
            </Container>
            <NewsletterForm />
          </Background>
        </Fragment>
      </ThemeProvider>
    </div>
  );
}
