import styled from 'styled-components';
import { colours, StoryTheme } from '../../styles/Themes';
import { space } from '../../styles/Layout';
import { SectionHeader, WrapStyles } from '../../styles/Layout';
import { fontFamily, fontSize } from '../../styles/Typography';
import LockIcon from '../icons/LockIcon';

export const Container = styled.div<{ theme: StoryTheme }>`
  background: ${({ theme }) => theme.gradient2 };
  color: ${colours.white};
  position: relative;
`

export const Wrap = styled.div`
  padding-left: ${space(1)};
  padding-right: ${space(1)};
  padding-top: ${space(4)};
  padding-bottom: ${space(8)};
  margin: 0 auto;
  max-width: 928px;
  position: relative;
` 

export const Slide = styled.div`
  width: 100%;
  display: block;
  height:100%;
  position: relative;
  overflow: hidden;
  padding-left: ${space(0.5)};
  padding-right: ${space(0.5)};
`

export const Card = styled.div<{ color: string; }>`
  background-color: ${({ color }) => color};
  width: 100%;
  display: block;
  min-height: 180px;
  height: 100%;
  position: relative;
  overflow: hidden;
  img {
    margin-top: -20px;
    position: relative;
    z-index: 0;
    mix-blend-mode: screen;
    width: 100%;
  }
`

export const CardTitle = styled.strong`
  color: #000000;
  opacity: 0.4;
  font-size: ${fontSize.xxlarge};
  font-family: ${fontFamily.fullFeatherWeight};
  letter-spacing: 0.24px;
  text-align: center;
  display: block;
  position: absolute;
  top: 10px;
  left: 0;
  right: 0;
  z-index: 1;
  line-height: 1.12em;
  text-transform: uppercase;
  mix-blend-mode: overlay;
  padding-left: ${space(0.5)};
  padding-right: ${space(0.5)};
`

export const CardScore = styled.span`
  color: ${colours.white};
  letter-spacing:  0.19px;
  font-size: ${fontSize.xlarge};
  font-family: ${fontFamily.fullFeatherWeight};
  text-transform: uppercase;
  text-align: center;
  display: block;
  padding-left: ${space(0.5)};
  padding-right: ${space(0.5)};
  position: absolute;
  bottom: 10px;
  left: 0;
  right: 0;

  span {
    font-family: ${fontFamily.middleWeight};
    font-size: ${fontSize.small};
  }
`

export const CardEmpty = styled.div`
  border: dashed 2px rgba(0, 0, 0, 0.2);
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 12px;
  width: 100%;
  display: block;
  height: 100%;
  min-height: 180px;
  position: relative;
  strong {
    color: #fff;
    opacity: 0.2;
    font-size: ${fontSize.xxlarge};
    font-family: ${fontFamily.fullFeatherWeight};
    letter-spacing: 0.24px;
    text-align: center;
    display: block;
    position: relative;
    margin-top: 10px;
    line-height: 1.12em;
    text-transform: uppercase;
    mix-blend-mode: overlay;
    padding-left: ${space(0.5)};
    padding-right: ${space(0.5)};
  }
`

export const Lock = styled(LockIcon)`
  margin: 0 auto;
  position: absolute;
  bottom: 20px;
  left: 0;
  right: 0;
  display: block;
  opacity: 0.5;
  path {
    fill: ${colours.white}};
  }
`

export const Header = styled(SectionHeader)`
  z-index: 20;
  position: relative;
  ${WrapStyles}
`
