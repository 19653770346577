import React, { FC, useState, ReactNode, Fragment } from 'react';

// styles.
import { Wrap, Container, Souvenir, Thumbnails, Photo, Empty, Lock, Share } from './Souvenirs.styles';
import { SlimHeading } from '../../styles/Typography';
import { SectionHeader } from '../../styles/Layout';

// types.
import { HeadInTheCrowdsPhoto, DayAtTheRacesProfile } from '../../userData';

// background.
import dayAtTheRacesBackground from "../../images/day-at-the-races-background.png";
import youMakeTheRulesBackground from "../../images/you-make-the-rules-background.jpg";

import { VIEW_TYPES } from "../../pages/SharePage";

// components.
import Modal from '../Modal'
import VideoPlayer from '../VideoPlayer';
import PhotoGallery from '../PhotoGallery';
import ImageGenerator from '../ImageGenerator';
import Count from '../Count'
import SocialUrl from '../SocialUrl';


// images.
import classicCatchBackground from '../../images/classic-catch-background.jpg'

const EmptySouvenir = () => (
  <Empty>
    <div>
      <Lock size="small"/>
      <strong>Find me<br/>in the Museum!</strong>
    </div>
  </Empty>
)

// Views
const ClassicCatch: FC<{ url: string | null, publicId: string }> = ({ url, publicId }) => {
  return url ? (
    <Fragment>
      <Souvenir>
        <VideoPlayer url={url} />
        <SlimHeading>Classic Catch</SlimHeading>
      </Souvenir>
      <Share><SocialUrl url={`${window.location.origin}/share/${publicId}/${VIEW_TYPES.CLASSIC_CATCH}`} /></Share>
    </Fragment>
  ): null
}

const YouMakeTheRules: FC<{ image: string | null, publicId: string }> = ({ image, publicId }) => {
  return image ? (
    <Fragment>
      <Souvenir>
        <img src={image} alt='You make the rules'/>
        <SlimHeading>You make the rules</SlimHeading>
      </Souvenir>
      <Share><SocialUrl url={`${window.location.origin}/share/${publicId}/${VIEW_TYPES.YOU_MAKE_THE_RULES}`} /></Share>
    </Fragment>
  ): null
}

const DayAtTheRaces: FC<{ image: string | null, publicId: string }> = ({ image, publicId }) => {
  return image ? (
    <Fragment>
      <Souvenir>
        <img src={image} alt="Day at the races" />
        <SlimHeading>Day at the races</SlimHeading>
      </Souvenir>
      <Share><SocialUrl url={`${window.location.origin}/share/${publicId}/${VIEW_TYPES.DAY_AT_THE_RACES}`} /></Share>
    </Fragment>
  ): null
}

type ThumbnailProps = {
  imageUrl?: string | null;
  url?: string | null;
  clickHandler?: (content: ReactNode) => void;
  view?: ReactNode;
}

const Thumbnail: FC<ThumbnailProps> = ({ imageUrl, clickHandler, view }) => {
  const handler = () => {
    if(clickHandler && view) clickHandler(view)
  }
  return imageUrl ? <Photo key={imageUrl} onClick={handler} image={imageUrl}></Photo> : null
}

type Props = {
  headInTheCrowds: HeadInTheCrowdsPhoto[];
  youMakeTheRules: boolean;
  classicCatchVideoUrl: string | null;
  dayAtTheRacesProfile: DayAtTheRacesProfile | null;
  avatar: string | null;
  publicId: string;
}

const Souvenirs: FC<Props> = ({ headInTheCrowds, youMakeTheRules, classicCatchVideoUrl, dayAtTheRacesProfile, avatar, publicId }) => {
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [modalContent, setModalContent] = useState<ReactNode | null>(null);
  const closeHandler = () => {
    setModalOpen(false)
    setModalContent(null)
  }
  const openHandler = (content:ReactNode) => {
    setModalContent(content)
    setModalOpen(true)
  }
  const [youMakeTheRulesImage, setYouMakeTheRulesImage] = useState<string | null>(null)
  const [dayAtTheRacesImage, setDayAtTheRacesImage] = useState<string | null>(null)
  const souvenirCount = () => {
    return [youMakeTheRulesImage, headInTheCrowds.length, classicCatchVideoUrl, dayAtTheRacesImage].reduce((acc: any, curr: any) => !!curr ? acc + 1 : acc , 0);
  }

  const souvenirThumbnails = [
    Thumbnail({
      clickHandler: openHandler,
      view: <PhotoGallery photos={headInTheCrowds} title={'Head in the crowds'} publicId={publicId}/>,
      imageUrl: headInTheCrowds.length ? headInTheCrowds[0].photo_url : null,
    }),
    Thumbnail({
      clickHandler: openHandler,
      view: <YouMakeTheRules image={youMakeTheRulesImage} publicId={publicId}/>,
      imageUrl: youMakeTheRulesImage,
    }),
    Thumbnail({
      clickHandler: openHandler,
      view: <ClassicCatch url={classicCatchVideoUrl} publicId={publicId}/>,
      imageUrl: classicCatchVideoUrl ? classicCatchBackground : null,
      
    }),
    Thumbnail({
      clickHandler: openHandler,
      view: <DayAtTheRaces image={dayAtTheRacesImage} publicId={publicId}/>,
      imageUrl: dayAtTheRacesImage,
    }),
  ]
  return <Container>
    <Wrap>
      <SectionHeader>
        <SlimHeading uppercase={true}>Your Souvenirs</SlimHeading>
        <Count total={4} amount={souvenirCount()} divider={'/'} />
      </SectionHeader>
      <Thumbnails>
        {souvenirThumbnails
          .sort(
            (a:JSX.Element | null, b: JSX.Element | null) => {
              if (a !== null && b !== null) {
                return -(a > b) || +(a < b);
              }
              return Number(a === null) -  Number(b === null);
            }
          )
          .map((Element: JSX.Element | null, index) => Element !== null ? Element : <EmptySouvenir key={`empty-${index}`}/>)
        }
      </Thumbnails>
    </Wrap>
    <Modal isOpen={modalOpen} onClose={closeHandler}>
      {modalContent}
    </Modal>
    {dayAtTheRacesProfile &&
      <ImageGenerator
        backgroundUrl={dayAtTheRacesBackground}
        imageDetails={{
          url: dayAtTheRacesProfile.horse_image_url,
          pos: { x: 715, y: 190, scale: 0.33 },
        }}
        withFont={true}
        storeImage={setDayAtTheRacesImage}
        text={{
          value: dayAtTheRacesProfile.horse_name.toUpperCase(),
          pos: { x: 1100, y: 190 }
        }}
      />}

    {avatar && youMakeTheRules &&
      <ImageGenerator
        backgroundUrl={youMakeTheRulesBackground}
        imageDetails={{
          url: avatar.replace("v8.0.0/512x512", "ymtr-avatars"),
          pos: { x: 242, y: 360 }
        }}
        storeImage={setYouMakeTheRulesImage}
      />}
  </Container>
}


export default Souvenirs;
