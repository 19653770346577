import React from "react";

import Icon, { IconSize } from "./Icon";

type Props = {
  size: IconSize
}

const ArrowLeft = ({ size, ...rest }: Props) => (
  <Icon {...rest} size={size}>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 64">
    <path fillRule="evenodd" stroke="#FFF" strokeOpacity=".648" d="M10.855 32.369L1.322 1.5h4.903l16.342 30.873L6.221 62.5H1.317l9.538-30.131z"/>    </svg>
  </Icon>
);

export default ArrowLeft;