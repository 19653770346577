import React, { FunctionComponent } from "react";
import styled from "styled-components";

const StyledAvatarContainer = styled.div`
  width: 88px;
  height: 88px;
  display: inline-block;
`;

const AvatarContainer: FunctionComponent = ({ children }) => (
  <StyledAvatarContainer>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 96 96">
      <g>
        <circle cx="48" cy="48" r="48" fill="#FFF" />
        {children}
      </g>
    </svg>
  </StyledAvatarContainer>
);

export default AvatarContainer;
