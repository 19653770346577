import React from "react";

import AvatarContainer from "./AvatarContainer";

export default (
  <AvatarContainer>
    <path
      fill="inherit"
      d="M48 4c24.3 0 44 19.7 44 44S72.3 92 48 92 4 72.3 4 48 23.7 4 48 4zm0 4C25.909 8 8 25.909 8 48s17.909 40 40 40 40-17.909 40-40S70.091 8 48 8zM28.373 52.487l15.212 14.736a3.36 3.36 0 01.092 4.712l-6.72 7.043a3.32 3.32 0 01-4.689.104L17.022 64.465a3.353 3.353 0 01-.112-4.706l6.796-7.173a3.285 3.285 0 014.667-.099zm30.884-36.29C69.95 21.5 76.646 32.528 77.95 35.56l.588 1.347c1.462 3.448 3.386 9.473-2.963 15.972l-.673.685C67.821 60.734 60.28 67.002 46.848 68.49L26.755 49.2l-.075-.368c-.358-1.892-1.698-10.678 2.78-17.45 4.95-7.489 7.953-9.006 12.517-6.608.044.068.1.127.17.174l.098.055.138.072c.14.073.303.162.487.265a36.605 36.605 0 017.086 5.25 31.057 31.057 0 012.322 2.457c1.86 2.201 2.335 4.358 1.723 6.37-.472 1.55-1.613 2.944-2.742 3.61-2.045 1.205-5.125.29-7.766-2.851a.613.613 0 00-.872-.07.632.632 0 00-.07.882c2.9 3.45 6.47 4.592 9.072 3.264l.192-.104.076.097c1.194 1.498 2.472 2.754 3.824 3.78l.407.3c4.416 3.17 9.093 3.753 13.276 2.854.63-.135 1.088-.273 1.344-.369a.642.642 0 00.37-.815.613.613 0 00-.792-.38l-.138.046a11.455 11.455 0 01-1.036.272c-3.878.834-8.216.292-12.317-2.652-1.416-1.015-2.753-2.297-4-3.864 1.023-.898 1.919-2.2 2.354-3.63.738-2.431.165-5.032-1.965-7.555a32.3 32.3 0 00-2.416-2.556 37.831 37.831 0 00-7.334-5.433l-.697-.373.269-.475c2.017-3.476 7.747-11.398 16.217-7.198z"
    />
  </AvatarContainer>
);
