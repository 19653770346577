import React, { FC, Fragment, ReactNode, useState } from 'react';
import { GalleryEntry, Collection } from "../../contentfulModels";
import { GroupedPoll } from "../../helpers/polls";
import { GroupedQuiz } from "../../helpers/quizzes";
import styled from 'styled-components';
import { TwoColumn, space, WrapStyles, media } from '../../styles/Layout';
import { StoryTheme } from '../../styles/Themes';
import { VIEW_TYPES } from "../../pages/SharePage";
import { pluralize } from "../../utils";

// components.
import Slideshow from '../Slideshow'
import ArcResult from '../results/ArcResult';
import DotsResult from '../results/DotsResult';
import CircleScaleResult from '../results/CircleScaleResult';
import ImageWipeResult from '../results/ImageWipeResult';
import { BoldHeading } from '../../styles/Typography';
import EmptyResult from '../results/EmptyResult';
import Stat from "../Stat";
import { getGalleryTheme } from '../../styles/Themes';
import { ThemeProvider } from "styled-components";
import { contentfulImageLinkUrl } from "../../helpers/contentful";
import Modal from "../Modal";
import SocialUrl from '../SocialUrl';


// icons.
import PollsIcon from '../icons/PollsIcon';
import TriviaIcon from '../icons/TriviaIcon';


type Props = {
  gallery: GalleryEntry;
  polls: GroupedPoll[];
  quizzes: GroupedQuiz[];
  contentfulCollection: Collection;
  publicId: string;
};

export const ModalWrap = styled.div`
  background: #fff;
  padding-left: ${space(1)};
  padding-right: ${space(1)};
  padding-top: ${space(7)};
  padding-bottom: ${space(2)}
  width: 100%;
  & > * {
    margin-bottom: ${space(2)}
  }
`

export const Share = styled.div`
  display: flex;
  justify-content: center;
  margin-top: ${space(1)};
`

const Result = {
  "Arc": ArcResult,
  "Image Scale": CircleScaleResult,
  "Dots": DotsResult,
  "Wipe" :ImageWipeResult,
};

const getPercentages = (option_a_count: number, option_b_count: number, title: string): {
  responsePercentLeft: number;
  responsePercentRight: number;
} => {
  const total = option_a_count + option_b_count;
  return {
    responsePercentLeft: option_a_count / total,
    responsePercentRight: option_b_count / total
  }
}

const footerText = (vote: 'A' | 'B' | null, groupA: number, groupB: number) => {
  if(groupA === 0 && groupB === 0) return null
  if(groupA === groupB) return 'You group is 50/50 on this one.'
  const text = (num: number, agree: boolean) =>  {
    const agreementText = agree ? "agreed" : "disagreed"

    if (num <= 4) {
      return `Most of your group ${agreementText} with you`
    } else {
      return `${num} from your group ${agreementText} with you`
    }
  }
  switch(vote) {
    case 'A': 
      return text(groupA > groupB ? groupA : groupB, groupA > groupB)
    case 'B':
      return text(groupA < groupB ? groupB : groupA, groupA < groupB)
    default:
      return null
  } 
}

export const makePollResult = ({
  data,
  contentfulCollection,
  openHandler,
  publicId,
  galleryId
}: {
  data: GroupedPoll,
  contentfulCollection: Collection,
  openHandler?: (view: ReactNode) => void,
  publicId?: string
  galleryId?: number
}) => {
  const { poll: { fields, sys }, profileResult, result } = data
  if(!fields.resultStyle || !result || !fields.questionText || !profileResult) return null
  // If a poll's result style is misconfigured, skip over this poll
  if(!(fields.resultStyle && Object.keys(Result).includes(fields.resultStyle))) return null
  const View = Result[fields.resultStyle];
  const percentages = getPercentages(result.option_a_count, result.option_b_count, fields.questionText);
  let images = {}
  if(fields.resultAImage && fields.resultBImage) {
    const leftImageUrl = contentfulImageLinkUrl(
      fields.resultAImage,
      contentfulCollection,
      { height: 330 }
    )
    const rightImageUrl = contentfulImageLinkUrl(
      fields.resultBImage,
      contentfulCollection,
      { height: 330 }
    )
    images = {
      leftImageUrl,
      rightImageUrl,
    }
  }
  if(!result && !fields.questionText && !profileResult) return null
  const clickHandler = () => {
    const Result = <Fragment>
      <ModalWrap>
        {makePollResult({data, contentfulCollection })}
      </ModalWrap>
      <Share><SocialUrl url={`${window.location.origin}/share/${publicId}/${VIEW_TYPES.POLL_RESULT}/${galleryId}|${sys.id}`} /></Share>
    </Fragment>
    if(Result && openHandler) openHandler(Result)
  }
  const { group_option_a_count, group_option_b_count, vote } = profileResult
  const footer = footerText(vote, group_option_a_count, group_option_b_count)
  return <View
    key={sys.id}
    {...percentages}
    {...images}
    footer={footer}
    title={fields.questionText}
    vistorChose={profileResult.vote === 'A' ? 'left' : 'right'}
    leftText={fields.answerAText}
    rightText={fields.answerBText}
    clickHandler={clickHandler}
  />
}

type HeaderProps = {
  title: string;
  pollsAnswered?: number;
  pollsMissed?: number;
  quizzesAnswered?: number;
  quizzesMissed?: number;
}

const Header = styled.div`
  ${TwoColumn};
  ${WrapStyles}
  & > * {
    align-self: center;
    margin-bottom: ${space(2)};
  }
`

const Stats = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  & > * {
    margin-right: ${space(2)};
  }
`

const Decoration = styled.div<{ theme: StoryTheme }>`
  margin-left: 8px;
  width: calc(100% - 8px);
  border-bottom: 2px solid ${({ theme }) => theme.line3 };
  height: 16px;
  overflow: hidden;
  margin-bottom: ${space(3)};
  margin-top: ${space(2)};
  display: block;
  ${media.medium} {
    margin-left: calc((100% - 768px) / 2 + 8px);
    width: calc(100% - (100% - 768px) / 2 - 8px);
  }
  &:before {
    content:'';
    background: ${({ theme }) => theme.line2 };
    display: block;
    position: relative;
    width: 100%;
    height: 2px;
    top: 8px;  
    left: 8px;
  }
  &:after {
    content:'';
    display: block;
    background: ${({ theme }) => theme.line1 };
    position: relative;
    width: 100%;
    height: 2px;
    left: 16px;
    top: 0;
  }
`

export const GalleryHeader: FC<HeaderProps> = ({
  title,
  pollsAnswered,
  pollsMissed,
  quizzesAnswered,
  quizzesMissed,
}) => {
  return (
    <div>
      <Decoration />
      <Header>
        <BoldHeading>{title}</BoldHeading>
        {(pollsAnswered !== undefined &&
          quizzesAnswered  !== undefined &&
          pollsMissed !== undefined &&
          quizzesMissed !== undefined) && 
          <Stats>
            <Stat Icon={PollsIcon} title={`${pollsAnswered}% answered`} subTitle={`${pollsMissed} missed`}/>
            <Stat Icon={TriviaIcon} title={`${quizzesAnswered} trivia ${pluralize("point", quizzesAnswered)}`} subTitle={`${quizzesMissed} missed`}/>
          </Stats>
        }
      </Header>
    </div>
  ) 
}

const Gallery: FC<Props> = ({
  gallery,
  polls,
  quizzes,
  contentfulCollection,
  publicId,
}) => {
  const pollsTotal = polls.length
  const pollsAnswered = polls.filter(
    poll => poll.profileResult && poll.profileResult.vote !== null
  ).length 
  const quizzesTotal = quizzes.length;
  const quizzesAnswered = quizzes.filter(({ result }) => result && result.correct).length

  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [modalContent, setModalContent] = useState<ReactNode | null>(null);

  const closeHandler = () => {
    setModalOpen(false)
    setModalContent(null)
  }

  const openHandler = (content:ReactNode) => {
    setModalContent(content)
    setModalOpen(true)
  }
  if(!gallery.fields.id) return null
  return (
    <ThemeProvider
      theme={getGalleryTheme(gallery.fields.id)}
    >
      <Fragment>
        {gallery.fields.name && <GalleryHeader
          title={gallery.fields.name}
          pollsAnswered={Math.round(pollsAnswered / pollsTotal * 100)}
          pollsMissed={pollsTotal - pollsAnswered}
          quizzesAnswered={quizzesAnswered}
          quizzesMissed={quizzesTotal - quizzesAnswered}
        />}
        <Slideshow
          slides={polls
            .map( data => makePollResult({data, contentfulCollection, openHandler, publicId, galleryId: gallery.fields.id}))
            .sort(
              (a:JSX.Element | null, b: JSX.Element | null) => {
                if (a !== null && b !== null) {
                  return -(a > b) || +(a < b);
                }
                return Number(a === null) -  Number(b === null);
              }
            )
            .map((result, i) => result !== null ? result : <EmptyResult key={`empty-${i}`} /> )
          }
          slidesToShow={2}
          slidesToScroll={2}
          infinite={false}
          responsive={[
            { breakpoint: 1024, settings: { slidesToShow: 2, slidesToScroll: 2 } },
            { breakpoint: 900, settings: { slidesToShow: 1, slidesToScroll: 1 } },
            { breakpoint: 768, settings: { slidesToShow: 2, slidesToScroll: 2, centerMode: false, } },
            { breakpoint: 600, settings: { slidesToShow: 1, slidesToScroll: 1, centerMode: false, } } ]
          }
          />
          <Modal isOpen={modalOpen} onClose={closeHandler}>
            {modalContent}
          </Modal>
      </Fragment>
    </ThemeProvider>
  )
}


export default Gallery
