import React, { FunctionComponent } from "react";
import { Title, Answers, Visual, Container, Answer, Percent, Chose, Footer, Wrap } from "./Result.styles";
import { colours } from '../../styles/Themes'
export type ResultProps = {
  responsePercentLeft: number;
  responsePercentRight: number;
  leftText?: string;
  rightText?: string;
  leftImageUrl?: string;
  rightImageUrl?: string;
  title: string;
  vistorChose: 'left' | 'right';
  footer: string | null;
  clickHandler?: () => void;
};

export type AlignProps = {
  align: "left" | "right";
};

export const getColors = (value1: number, value2: number, color: string): {
  percent1Color: string;
  percent2Color: string;
} => {
  return {
    percent1Color: value1 >= 0.5 ? color : colours.lightGrey,
    percent2Color: value2 > 0.5  ? color : colours.lightGrey,
  }
}

const VistorsChoice = () => <Chose>You chose</Chose>

const Percentage = ({ percent, isMajority }: { percent: number, isMajority: boolean }) => <Percent isMajority={isMajority}>{Math.round(percent * 100)}%</Percent>

const Result: FunctionComponent<ResultProps> = ({
  responsePercentLeft,
  responsePercentRight,
  leftText,
  rightText,
  title,
  vistorChose,
  children,
  footer,
  clickHandler
}) => {
  const majority = (responsePercentLeft - responsePercentRight >= 0)
  const handler = clickHandler ? { onClick: clickHandler } : null
  return (
    <Container {...handler}>
      <Wrap hasFooter={!!footer}>
        <Title>{title.replace("\\n", " ")}</Title>
        <Visual>
          <Percentage percent={responsePercentLeft} isMajority={majority}/>
          {children}
          <Percentage percent={responsePercentRight} isMajority={!majority}/>
        </Visual>
        <Answers>
          <div>
            {vistorChose === 'left' && <VistorsChoice/>}
            <Answer isMajority={majority}>{leftText}</Answer>
          </div>
          <div>
            {vistorChose === 'right' && <VistorsChoice/>}
            <Answer isMajority={!majority}>{rightText}</Answer>
          </div>
        </Answers>
      </Wrap>
      {footer && <Footer>{footer}</Footer>}
    </Container>
  );
};


export default Result;
