import React, { Fragment, FC, useState } from 'react';
import { Container, Title, Share } from './PhotoGallery.styles'
import Slideshow from '../Slideshow';
import SocialUrl from '../SocialUrl';
import { HeadInTheCrowdsPhoto } from '../../userData';

const PhotoGallery: FC<{ photos: HeadInTheCrowdsPhoto[], title: string, publicId: string }> = ({ photos, title, publicId }) => {
  const [url, setUrl] = useState<string>(`${window.location.origin}/share/${publicId}/head-in-the-crowds/1`)
  const changeHandler = (index: number) => {
    setUrl(`${window.location}/share/${publicId}/head-in-the-crowds/${index + 1}`)
  }
  return (
    <Fragment>
      <Container>
        <Slideshow
          showSlideCount={true}
          onSlideChange={changeHandler}
          slides={photos.map(({ photo_url, base_crowd_image_name }: { photo_url: string; base_crowd_image_name: string; }) => <div><img src={photo_url} alt={base_crowd_image_name}/></div>)}
          lightTheme={true}/>
        <Title>{title}</Title>   
      </Container>
      <Share><SocialUrl url={url} /></Share>
    </Fragment>
  )
}

export default PhotoGallery