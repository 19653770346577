import React, { useContext } from "react";
import styled, { ThemeContext } from "styled-components";
import Result, { ResultProps, getColors } from "./Result";

const Grid = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  width: 50%;
`;

const GridCell = styled.div<{ align: 'left' | 'right', color1: string, color2: {} }>`
  width: 100%;
  height: 0;
  padding-bottom: 100%;
  display: flex;
  margin-bottom: 2px;
  border-radius: 50%;
  background-color: ${({ align, color1, color2 }) => align === "right" ? color1 : color2};
`;

const GridColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 6%;
  margin-right: 2px;
`;

const ROWS = 8;
const COLUMNS = 16;

const DotsResult = ({ responsePercentLeft, responsePercentRight, ...props }: ResultProps) => {
  let grid = [];
  const totalCells = ROWS * COLUMNS;
  const leftCells = Math.floor(totalCells * responsePercentLeft);
  let currentCellCount = 0;
  const theme = useContext(ThemeContext);
  const colors = getColors(responsePercentRight, responsePercentLeft, theme.background1)
  for (let column = 0; column < COLUMNS; column++) {
    let cells = [];
    for (let row = 0; row < ROWS; row++) {
      currentCellCount += 1;
      cells.push(
        <GridCell
          align={currentCellCount > leftCells ? "right" : "left"}
          color1={colors.percent1Color}
          color2={colors.percent2Color}
          key={`cell-${row}-${column}`}
        />
      );
    }
    grid.push(<GridColumn key={`column-${column}`}>{cells}</GridColumn>);
  }

  return (
    <Result responsePercentLeft={responsePercentLeft} responsePercentRight={responsePercentRight} {...props}>
      <Grid>{grid}</Grid>
    </Result>
  );
};

export default DotsResult;
