
import React, { FC } from 'react';
import styled from 'styled-components';
import { fontFamily, fontSize } from '../styles/Typography';

export const Counter = styled.span`
  font-size: ${fontSize.medium};
  font-family: ${fontFamily.juniorWelterWeight};
  text-align: right;
`

type Props = {
  amount: number | string;
  total: number | string;
  divider: string; 
}
const Count: FC<Props> = ({ amount, total, divider }) => <Counter>{`${amount} ${divider} ${total}`}</Counter>

export default Count
