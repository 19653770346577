import styled from 'styled-components';
import { fontSize, fontFamily } from '../../styles/Typography'
import { StoryTheme } from "../../styles/Themes";
import { space, media } from "../../styles/Layout";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  min-height: 220px;
  overflow: hidden;
  padding-left: ${space(0.5)};
  padding-right: ${space(0.5)};
  height: 100%;
`;

export const Wrap = styled.div<{ hasFooter: boolean}>`
  background: rgba(0, 0, 0, 0.02);
  border: 1px solid #ebebeb;
  flex: 1;
  ${({ hasFooter }) => hasFooter ? 'border-bottom: none;' : null}
  border-radius: ${({ hasFooter }) => hasFooter ? '8px 8px 0 0' : '8px' };
  padding: ${space(2)} ${space(1)};
  ${media.small} {
    padding: ${space(2)} ${space(3)};
  }
  ${media.medium} {
    padding: ${space(2)} ${space(5)};
  }
`

export const Footer = styled.strong<{ theme: StoryTheme }>`
  text-align: center;
  background: ${({ theme }) => theme.background1 };
  border: 1px solid ${({ theme }) => theme.background1 };
  border-radius: 0 0 8px 8px;
  color: ${({ theme }) => theme.text };
  padding: ${space(2)} ${space(1)};
  font-size: ${fontSize.xsmall};
  letter-spacing: 0.07px;
  text-transform: uppercase;
  font-family: ${fontFamily.juniorWelterWeight};
  width: 100%;
  display: block;
`

export const Title = styled.h4`
  font-size: ${fontSize.small};
  font-family: ${fontFamily.middleWeight};
  letter-spacing: 0.08px;
  text-align: center;
  margin-bottom: ${space(3)};
  
`

export const Visual = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: ${space(2)};
`

export const Percent = styled.strong<{ theme: StoryTheme, isMajority?: boolean}>`
  font-size: ${fontSize.xlarge};
  font-family: ${fontFamily.ultimateMiddleWeight};
  letter-spacing: 0.2px;
  width: 25%;
  align-self: center;
  text-align: center;
  color: ${({ theme, isMajority }) => isMajority ? theme.background1 : theme.lightGrey };
`;

export const Answers = styled.div`
  display: flex;
  flex-direction: row;
  & > * {
    width: 50%;
  }
`

export const Answer = styled.strong<{ theme: StoryTheme, isMajority?: boolean}>`
  text-align: center;
  text-transform: uppercase;
  font-size: ${fontSize.small};
  font-family: ${fontFamily.middleWeight};
  color: ${({ theme, isMajority }) => isMajority ? theme.background1 : theme.lightGrey };
  display: block;
  margin-top: ${space(1.5)};
`

export const Chose = styled.span`
  font-size: ${fontSize.xxsmall};
  font-family: ${fontFamily.cruiserWeight};
  display: block;
  text-transform: uppercase;
  text-align: center;
  height: ${space(1.5)};
  & ~ ${Answer} {
    margin-top: 0;
  }
`


