import React, { ReactNode } from "react";
import styled from "styled-components";

export type IconSize = 'small' | 'medium' | 'large' | 'xLarge'

const ICON_SIZES = {
  small: '24px',
  medium: '36px',
  large: '44px',
  xLarge: '96px',
}

const IconContainer = styled.div<{ size: IconSize }>`
  width: ${({ size }) => ICON_SIZES[size]};
  display: inline-block;
`;

type Props = {
  children: ReactNode;
  size: IconSize;
}

const Icon = ({ children, size, ...rest }: Props) => (
  <IconContainer {...rest} size={size}>
    {children}
  </IconContainer>
);

export default Icon;
