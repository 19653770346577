import React, { FC } from 'react';
import styled from 'styled-components';
import { fontSize, fontFamily } from '../styles/Typography';
import { space } from '../styles/Layout';

const StatBlock = styled.div`
  flex-direction: row;
  display: flex;
  path {
    fill: ${({theme }) => theme.pop1 };
  }
  rect {
    fill: ${({theme }) => theme.background2 };
  }
`

const Titles = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: ${space(1)};
`

const Title = styled.strong<{ hasSubTitle: boolean}>`
  font-size: ${fontSize.medium};
  ${({ hasSubTitle }) => hasSubTitle ? `
    font-family: ${fontFamily.juniorFeatherWeight};
    margin-bottom: ${space(0.5)};
  ` : `
    font-family: ${fontFamily.juniorWelterWeight};
  `}
`

const Subtitle = styled.span`
  font-size: ${fontSize.small};
  font-family: ${fontFamily.juniorWelterWeight};
  text-transform: uppercase;
  color: ${({theme }) => theme.black35 };

`

type Props = {
  title: string;
  subTitle?:string;
  Icon: any;
}
const Stat: FC<Props> = ({ title, subTitle, Icon }) => {
  return <StatBlock>
    <Icon size={subTitle ? 'large' : 'small' } />
    <Titles>
      <Title hasSubTitle={!!subTitle}>{title}</Title>
      {subTitle && <Subtitle>{subTitle}</Subtitle>}
    </Titles>
  </StatBlock>
}

export default Stat
