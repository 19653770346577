import React, { useState, useEffect, Fragment } from "react";
import styled, { ThemeProvider } from 'styled-components';
import { Collection, GalleryEntry, PollEntry } from "../contentfulModels";
import { UserDataClient, PollSummary, ProfilePollSummary, ProfileResponse } from "../userData";
import { groupPollsByGallery } from "../helpers/contentful";
import { GalleryHeader } from '../components/Gallery/Gallery';
import Spinner from '../components/Spinner';
import { StoryTheme, getGalleryTheme, colours } from '../styles/Themes';
import { space, Wrap, media, Footer } from '../styles/Layout';
import { fontFamily, fontSize } from "../styles/Typography";
import AngleHeading from '../components/AngleHeading';
import { groupPollData } from "../helpers/polls";
import Logo from "../components/Logo";
import { Session } from "../helpers/session";
import Button from "../components/Button";
import { navigate } from "@reach/router";

const Background = styled.div`
  padding-top: 100px;
  padding-bottom: 100px;
  background: linear-gradient(45deg, #0b5ada, #11a4a7, #078f40, #92c61a, #ffcd4d, #fe5506, #f51830, #0b5ada);
  background-size: 1600% 1600%;
  -webkit-animation: AnimationName 120s ease infinite;
  -moz-animation: AnimationName 120s ease infinite;
  animation: AnimationName 120s ease infinite;
  @-webkit-keyframes AnimationName {
      0%{background-position:94% 0%}
      50%{background-position:7% 100%}
      100%{background-position:94% 0%}
  }
  @-moz-keyframes AnimationName {
      0%{background-position:94% 0%}
      50%{background-position:7% 100%}
      100%{background-position:94% 0%}
  }
  @keyframes AnimationName { 
      0%{background-position:94% 0%}
      50%{background-position:7% 100%}
      100%{background-position:94% 0%}
  }
  display: block;
`

const Container = styled.div`
  max-width: 1024px;
  padding: 40px 0px;
  background: #fff;
  margin: 0 auto;
`

const Loading = styled.div<{ theme: StoryTheme }>`
  background: ${({ theme }) => theme.gradient2 };
  height: 100vh;
  display: flex;
  & > * {
    margin: auto;
    opacity: 0.7;
  }
  svg {
    width: 80px;
    height: 80px;
  }
`

const Table  = styled.table<{ theme: StoryTheme; }>`
  max-width: 768px;
  margin: 0 auto ${space(3)};
  display: flex;
  flex-direction: column;
  tr {
    display: flex;
    flex-direction: row;
    &:nth-child(odd) td {
      background: rgba(0,0,0,0.02);
    }
  }
  th, td {
    text-align: left;
    padding: ${space(1)} ${space(0.5)};
    display:flex;
  }

  th {
    font-family:${fontFamily.middleWeight};
    font-size: ${fontSize.small};
  }

  td {
    font-size: ${fontSize.small};
    padding-top: ${space(4)};
    padding-bottom: ${space(4)};
  }

  ol {
    width: 100%;
    display: flex;
    flex-direction: row;
    margin-top: ${space(2)}
  }

  td {
    min-height: 44px;
  }

  td:first-child {
    font-size: ${fontSize.small};
    ${media.medium} {
      font-size: ${fontSize.medium};
    }
  }

  th:first-child,
  td:first-child {
    flex: 1;
    padding-left: ${space(2)};
  }
  
  td:first-child {
    flex-direction: column;
  }

  td:nth-child(even) {
    // background: rgba(0,0,0,0.05);
  }

  th:nth-child(2),
  th:nth-child(3),
  th:nth-child(4) {
    width: 44px;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  td:nth-child(2),
  td:nth-child(3),
  td:nth-child(4) {
    width: 44px;
    font-size: ${fontSize.large};
    align-items: center;
    justify-content: center;
    text-align: center;
  }
`

const Answer = styled.li<{ theme: StoryTheme; vistorAnswer: boolean; }>`
  width: 50%;
  padding-right: ${space(1)};
  font-size: ${fontSize.small};
  font-weight: 300;
  ${media.medium} {
    font-size: ${fontSize.medium};
  }
  strong {
    color: ${({ theme,vistorAnswer  }) => vistorAnswer ? theme.background1 : colours.lightGrey }
    font-family:${fontFamily.middleWeight};
    font-size: ${fontSize.small};
    display: block;
    margin-bottom: ${space(0.5)};
  }
`

function groupTotalA(pollSummary: ProfilePollSummary | null): number {
  if (pollSummary) {
    if (pollSummary.vote === "A") {
      return pollSummary.group_option_a_count + 1
    } else {
      return pollSummary.group_option_a_count
    }
  } else {
    return 0
  }
}

function groupTotalB(pollSummary: ProfilePollSummary | null): number {
  if (pollSummary) {
    if (pollSummary.vote === "B") {
      return pollSummary.group_option_b_count + 1
    } else {
      return pollSummary.group_option_b_count
    }
  } else {
    return 0
  }
}

function groupTotal(pollSummary: ProfilePollSummary | null): number {
  if (pollSummary) {
    if (pollSummary.vote !== null) {
      return pollSummary.group_option_a_count + pollSummary.group_option_b_count + 1
    } else {
      return pollSummary.group_option_a_count + pollSummary.group_option_b_count
    }
  } else {
    return 0
  }
}

type Props = {
  path: string;
  contentfulCollection: Collection | 'loading' | 'error';
  userDataClient: UserDataClient;
  session: Session;
};
export function PollListPage({ contentfulCollection, userDataClient, session }: Props) {
  const [profile, setProfile] = useState<ProfileResponse | 'loading' | 'error'>('loading')
  const [pollResults, setPollResults] = useState<PollSummary[]>([]);

  useEffect(() => {
    async function loadData() {
      let summaries = await userDataClient.pollSummaries();
      const profileData = await userDataClient.profile({
        privateId: session.privateId
      });
      setProfile(profileData);
      setPollResults(summaries);

    }
    if (contentfulCollection !== 'error' && contentfulCollection !== 'loading') loadData();
  }, [userDataClient, session, contentfulCollection]);

  if (contentfulCollection === 'error' || profile === 'error') return (
    <Container>
      <Loading>
        Error Details here.
      </Loading>
    </Container>
  )
  if (contentfulCollection === 'loading' || profile === 'loading') return (
    <Container>
      <Loading>
        <Spinner />
      </Loading>
    </Container>
  )

  const galleries: GalleryEntry[] = Array.from(
    contentfulCollection.galleries.values()
  );
  const pollsByGallery = groupPollsByGallery(contentfulCollection);

  galleries
    .map(gallery => ({
      gallery,
      pollData: groupPollData({
        polls: pollsByGallery.get(gallery) as PollEntry[],
        results: pollResults,
        profileResults: profile.polls
      }),
    }))

  return (
    <Background>
      <Logo />
       <Wrap>
        <AngleHeading>Group Results</AngleHeading>
      </Wrap>
      <Container>
        {galleries
          .map(gallery => ({
            gallery,
            pollData: groupPollData({
              polls: pollsByGallery.get(gallery) as PollEntry[],
              results: pollResults,
              profileResults: profile.polls
            }),
          }))
          .map(({gallery, pollData }) => {
            return gallery.fields.id ? (
              <ThemeProvider theme={getGalleryTheme(gallery.fields.id)} key={gallery.fields.id}>
                <Fragment>
                  {gallery.fields.name && <GalleryHeader title={gallery.fields.name} />}
                  <Table>
                    <thead>
                      <tr>
                        <th>Question</th>
                        <th>A</th>
                        <th>B</th>
                        <th>Total</th>
                      </tr>
                    </thead>
                  <tbody>
                      {pollData.filter(({ poll, profileResult }) => groupTotal(profileResult) > 0).map(({ poll, profileResult }) => (
                      <tr key={poll.sys.id}>
                        <td>
                          {poll.fields.questionText}
                          <ol>
                            <Answer vistorAnswer={!!profileResult && profileResult.vote === 'A'}>
                              <strong>Answer A</strong>
                              {poll.fields.answerAText}
                            </Answer>
                            <Answer vistorAnswer={!!profileResult && profileResult.vote === 'B'}>
                              <strong>Answer B</strong>
                              {poll.fields.answerBText}
                            </Answer>
                          </ol>
                        </td>
                          <td>{groupTotalA(profileResult)}</td>
                          <td>{groupTotalB(profileResult)}</td>
                        <td>
                            {groupTotal(profileResult)}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Fragment>
            </ThemeProvider>
            ) : null
          })
        }
        <Footer>
          <Button onClick={() => { navigate('/profile') }}>Back to profile</Button>
        </Footer>
      </Container>
    </Background>
  );
}
