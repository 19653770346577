import React, { useEffect, useState, useRef } from "react";
import { Router, Redirect, navigate, globalHistory } from "@reach/router";

import { Session, storeSession, loadSession } from "./helpers/session";
import { UserDataClient } from "./userData";
import { loadCollection } from "./helpers/contentful";
import { Collection } from "./contentfulModels";
import { LoginPage } from "./pages/LoginPage";
import { PollListPage } from "./pages/PollListPage";
import { ProfilePage } from "./pages/ProfilePage";
import { SharePage } from "./pages/SharePage";
import GlobalStyle from "./styles/GlobalStyles";

globalHistory.listen(({ location }) => {
  (window as any).gtag('config', 'UA-158811313-1', { 'page_path': location.pathname });
});

export default function App() {
  const [session, setSession] = useState<Session | null>(loadSession());
  const [contentfulCollection, setContentfulCollection] = useState<
    Collection | "loading" | "error"
  >("loading");
  const userDataClient = useRef(new UserDataClient());

  let setAndStoreSession = (session: Session | null) => {
    setSession(session);
    storeSession(session);
  };

  // Load contentful collection
  useEffect(() => {
    async function loadData() {
      try {
        setContentfulCollection(await loadCollection());
      } catch (error) {
        setContentfulCollection("error");
      }
    }
    loadData();
  }, []);
  return (
    <>
        {session ? (
          <Router>
            {/* Authenticated routes */}
            <PollListPage
              path="/polls"
              session={session}
              contentfulCollection={contentfulCollection}
              userDataClient={userDataClient.current}
            />
            <ProfilePage
              path="/profile"
              onLogout={() => setAndStoreSession(null)}
              session={session}
              contentfulCollection={contentfulCollection}
              userDataClient={userDataClient.current}
            />
            <SharePage
              path="/share/:publicId/:viewType/*"
              contentfulCollection={contentfulCollection}
              userDataClient={userDataClient.current}
            />
            <Redirect noThrow from="/" to="/profile" />
          </Router>
        ) : (
          <Router>
            {/* Anonymous routes */}
            <LoginPage
              default={true}
              path="/"
              onLoginSuccessful={session => {
                setAndStoreSession(session)
                navigate("/profile")
              }}
              userDataClient={userDataClient.current}
            />
            <SharePage
              path="/share/:publicId/:viewType/*"
              contentfulCollection={contentfulCollection}
              userDataClient={userDataClient.current}
            />
            <Redirect noThrow from="/polls" to="/" />
            <Redirect noThrow from="/profile" to="/" />
          </Router>
        )}
       <GlobalStyle />
    </>
  );
}
