import React, { useState } from "react";
import { Formik, Form, Field } from "formik";
import { Session } from "../helpers/session";
import { UserDataClient } from "../userData";
import styled from 'styled-components';
import ReactCodeInput from 'react-code-input';
import ButtonComponent from '../components/Button';
import { SlimHeading, fontSize } from '../styles/Typography'
import { colours } from '../styles/Themes';
import { space } from '../styles/Layout';
import DateInput from "../components/DateInput";
import Spinner from "../components/Spinner";
import moment from 'moment'
import Logo from "../components/Logo";

type LoginStatus = "unsubmitted" | "submitting" | "loggedIn" | "failed" | "codeSet" | "codeSetSubmitting";

const Container = styled.div`
  padding-top: 200px;
  background: linear-gradient(45deg, #0b5ada, #11a4a7, #078f40, #92c61a, #ffcd4d, #fe5506, #f51830, #0b5ada);
  background-size: 1600% 1600%;
  -webkit-animation: AnimationName 80s ease infinite;
  -moz-animation: AnimationName 80s ease infinite;
  animation: AnimationName 80s ease infinite;
  @-webkit-keyframes AnimationName {
      0%{background-position:94% 0%}
      50%{background-position:7% 100%}
      100%{background-position:94% 0%}
  }
  @-moz-keyframes AnimationName {
      0%{background-position:94% 0%}
      50%{background-position:7% 100%}
      100%{background-position:94% 0%}
  }
  @keyframes AnimationName { 
      0%{background-position:94% 0%}
      50%{background-position:7% 100%}
      100%{background-position:94% 0%}
  }
  min-height: 100vh;
  display: block;
`

const Text = styled.p`
  font-family: "Helvetica";
  font-size: ${fontSize.small};
  color: #fff;
  width: 75%;
  margin: ${space(2)} auto 0;
`

const Heading = styled(SlimHeading)`
  color: #fff;
  margin-bottom: ${space(3)};
`

const Button = styled(ButtonComponent)`
  margin: ${space(3)} auto 0;
`

const FormWrap = styled.div`
  background: none;
  padding: 24px 16px;
  width: 100%;
  text-align: center;
  max-width: 400px;
  margin: 0 auto;
  max-height: 100%;
  will-change: transform;
`

const CodeFormInput = styled(ReactCodeInput)`
  display: flex !important; // marked important as ReactCodeInput hard codes inline-block style
  justify-content: center;
  input {
    font-family: monospace;
    border-radius: 8px;
    border: 2px solid ${colours.black35};
    margin: ${space(0.5)};
    max-width: 44px;
    width: 100%;
    height: 56px;
    font-size: ${fontSize.medium};
    box-sizing: border-box;
    text-align: center;
    color: black;
    background-color: white;
    &:focus {
      outline: none;
      border: 2px solid ${colours.black65};
    }
  }

`

const CodeInput = ({ form, ...props }: {form: any}) => {
  return (
    <CodeFormInput
      type='text'
      name="shortCode"
      fields={6}
      value={form.values['shortCode']}
      onChange={(value: string) => {
        form.setFieldValue('shortCode', value)
      }}/>
  )
}

const DatePicker = ({ form, ...props }: {form: any}) => {
  return (
    <DateInput
      name="visitDate"
      dateFormat="dd/MM/yyyy"
      selected={form.values['visitDate']}
      onSelect={(e: any) => {
        form.setFieldValue('visitDate', e) //when day is clicked
      }}
      onChange={(e: any) => {
        form.setFieldValue('visitDate', e) //only when value has changed
      }}
    />
  )
}

type Props = {
  path: string;
  default?: boolean
  onLoginSuccessful: (session: Session) => void;
  userDataClient: UserDataClient;
};
export function LoginPage(props: Props) {
  const userDataClient = props.userDataClient;
  const [loginStatus, setLoginStatus] = useState<LoginStatus>("unsubmitted");
  return (
    <Container>
      <Logo />
      <Formik
        initialValues={{ shortCode: "", visitDate: new Date() }}
        onSubmit={async (values, { resetForm }) => {
          try {
            const dateString = moment(values.visitDate).format("YYYY-MM-DD");
            if(loginStatus === 'codeSet') setLoginStatus("submitting")
            let loginResponse = await userDataClient.login({
              shortCode: values.shortCode,
              visitDate: dateString,
            });
            if (loginResponse.private_id !== null) {
              setLoginStatus("loggedIn");
              props.onLoginSuccessful({
                shortCode: values.shortCode,
                visitDate: dateString,
                privateId: loginResponse.private_id
              });
            } else {
              if(loginStatus === 'codeSetSubmitting') {
                setLoginStatus('codeSet');
              } else {
                resetForm();
                setLoginStatus("failed");
              }
            }
          } catch (error) {
            if(loginStatus === 'codeSetSubmitting') {
              setLoginStatus('codeSet');

            } else {
              resetForm();
              setLoginStatus("failed");
            }
          }
        }}
      >
        {props => (
          <FormWrap>
          <Form>
            {(loginStatus === 'failed' || loginStatus === 'unsubmitted' || loginStatus === 'codeSetSubmitting') &&
                <div>
                  <Heading uppercase={true}>Enter your special key</Heading>
                  <Field component={CodeInput} name="shortCode" />
                  <Text>Find your code printed on the label inside your sweatband</Text>
                  <Button type="submit" disabled={props.values.shortCode.length !== 6} onClick={() => { setLoginStatus('codeSetSubmitting') }}>{loginStatus === 'codeSetSubmitting' ? <Spinner /> : "Let's go!"}</Button>
                </div>
              }
              {(loginStatus === 'codeSet' || loginStatus === 'submitting')  &&
              <div>
                  <Heading uppercase={true}>When did you visit?</Heading>
                  <Field component={DatePicker} name="visitDate" />
                  <Button type="submit" disabled={loginStatus === 'submitting'}>{loginStatus === 'submitting' ? <Spinner /> : "Let's go!"}</Button>
              </div>
            }
            {loginStatus === "failed" ? (
              <Text>
                Login failed! Either your `Special key` or `Visit date` are incorrect.
              </Text>
            ) : null}
          </Form>
          </FormWrap>
        )}
      </Formik>
    </Container>
  );
}
