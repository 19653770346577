import { Entry, EntryFields, Asset } from "contentful";

export type LinkType = "Entry" | "Asset";

export type UnresolvedLink<T extends LinkType> = {
  sys: {
    id: string;
    type: "Link";
    linkType: T;
  };
};

function resolveLink<
  TLinkType extends LinkType,
  TType extends AllEntryTypes | Asset
>(
  dataset: Map<string, TType>,
  link: UnresolvedLink<TLinkType>
): TType | undefined {
  let result = dataset.get(link.sys.id);

  if (result === undefined) {
    // console.error("Unable to resolve link", link);
    return undefined;
  } else {
    return result;
  }
}

export type AudioContentModuleFields = {
  label?: EntryFields.Symbol;
  audio?: UnresolvedLink<"Asset">;
  image?: UnresolvedLink<"Asset">;
  transcript?: EntryFields.RichText;
  description?: EntryFields.RichText;
  credit?: EntryFields.RichText;
};
export type AudioContentModuleEntry = Entry<AudioContentModuleFields>;

export function isAudioContentModule(
  x: Entry<any>
): x is AudioContentModuleEntry {
  return x.sys.contentType.sys.id === "audioContentModule";
}

export type ConsoleFields = {
  id?: EntryFields.Symbol;
  title?: EntryFields.Symbol;
  showcases?: Array<UnresolvedLink<"Entry">>;
  heroImage?: UnresolvedLink<"Asset">;
  poll?: UnresolvedLink<"Entry">;
  stories?: Array<UnresolvedLink<"Entry">>;
};
export type ConsoleEntry = Entry<ConsoleFields>;

export function isConsole(x: Entry<any>): x is ConsoleEntry {
  return x.sys.contentType.sys.id === "console";
}

export type FunFactContentModuleFields = {
  title?: EntryFields.Symbol;
  funFact?: EntryFields.RichText;
  image?: UnresolvedLink<"Asset">;
};
export type FunFactContentModuleEntry = Entry<FunFactContentModuleFields>;

export function isFunFactContentModule(
  x: Entry<any>
): x is FunFactContentModuleEntry {
  return x.sys.contentType.sys.id === "funFactContentModule";
}

export type GalleryFields = {
  name?: EntryFields.Symbol;
  id?: EntryFields.Integer;
};
export type GalleryEntry = Entry<GalleryFields>;

export function isGallery(x: Entry<any>): x is GalleryEntry {
  return x.sys.contentType.sys.id === "gallery";
}

export type ImageContentModuleFields = {
  label?: EntryFields.Symbol;
  image?: UnresolvedLink<"Asset">;
  description?: EntryFields.RichText;
  credit?: EntryFields.RichText;
};
export type ImageContentModuleEntry = Entry<ImageContentModuleFields>;

export function isImageContentModule(
  x: Entry<any>
): x is ImageContentModuleEntry {
  return x.sys.contentType.sys.id === "imageContentModule";
}

export type ImageGalleryImageFields = {
  title?: EntryFields.Symbol;
  description?: EntryFields.RichText;
  images?: Array<UnresolvedLink<"Entry">>;
};
export type ImageGalleryImageEntry = Entry<ImageGalleryImageFields>;

export function isImageGalleryImage(
  x: Entry<any>
): x is ImageGalleryImageEntry {
  return x.sys.contentType.sys.id === "imageGalleryImage";
}

export type ObjectFields = {
  title?: EntryFields.Symbol;
  id?: EntryFields.Symbol;
  showcase?: UnresolvedLink<"Entry">;
  image?: UnresolvedLink<"Asset">;
  credit?: EntryFields.RichText;
};
export type ObjectEntry = Entry<ObjectFields>;

export function isObject(x: Entry<any>): x is ObjectEntry {
  return x.sys.contentType.sys.id === "object";
}

export type PollFields = {
  questionText?: EntryFields.Text;
  answerAText?: EntryFields.Text;
  answerBText?: EntryFields.Text;
  resultStyle?: PollResultStyle;
  resultAImage?: UnresolvedLink<"Asset">;
  resultBImage?: UnresolvedLink<"Asset">;
};
export type PollResultStyle = "Dots" | "Arc" | "Image Scale";
export type PollEntry = Entry<PollFields>;

export function isPoll(x: Entry<any>): x is PollEntry {
  return x.sys.contentType.sys.id === "poll";
}

export type PollContentModuleFields = {
  label?: EntryFields.Symbol;
  poll?: UnresolvedLink<"Entry">;
};
export type PollContentModuleEntry = Entry<PollContentModuleFields>;

export function isPollContentModule(
  x: Entry<any>
): x is PollContentModuleEntry {
  return x.sys.contentType.sys.id === "pollContentModule";
}

export type QuizFields = {
  questionText?: EntryFields.Symbol;
  correctAnswerText?: EntryFields.Symbol;
  correctImage?: UnresolvedLink<"Asset">;
  incorrectAnswerText?: EntryFields.Symbol;
  incorrectImage?: UnresolvedLink<"Asset">;
  resultText?: EntryFields.RichText;
};
export type QuizEntry = Entry<QuizFields>;

export function isQuiz(x: Entry<any>): x is QuizEntry {
  return x.sys.contentType.sys.id === "quiz";
}

export type QuizContentModuleFields = {
  label?: EntryFields.Symbol;
  quiz?: UnresolvedLink<"Entry">;
};
export type QuizContentModuleEntry = Entry<QuizContentModuleFields>;

export function isQuizContentModule(
  x: Entry<any>
): x is QuizContentModuleEntry {
  return x.sys.contentType.sys.id === "quizContentModule";
}

export type QuoteContentModuleFields = {
  quote?: EntryFields.RichText;
  attribution?: EntryFields.Symbol;
};
export type QuoteContentModuleEntry = Entry<QuoteContentModuleFields>;

export function isQuoteContentModule(
  x: Entry<any>
): x is QuoteContentModuleEntry {
  return x.sys.contentType.sys.id === "quoteContentModule";
}

export type ShowcaseFields = {
  id?: EntryFields.Symbol;
  label?: EntryFields.Symbol;
  gallery?: UnresolvedLink<"Entry">;
};
export type ShowcaseEntry = Entry<ShowcaseFields>;

export function isShowcase(x: Entry<any>): x is ShowcaseEntry {
  return x.sys.contentType.sys.id === "showcase";
}

export type StoryFields = {
  title?: EntryFields.Symbol;
  subtitle?: EntryFields.Symbol;
  themeColor?: StoryThemeColor;
  overviewImage?: UnresolvedLink<"Asset">;
  overviewImageCredit?: EntryFields.RichText;
  introductionText?: EntryFields.RichText;
  objects?: Array<UnresolvedLink<"Entry">>;
  contentItems?: Array<UnresolvedLink<"Entry">>;
};
export type StoryThemeColor =
  | "Red"
  | "Yellow"
  | "Green"
  | "Teal"
  | "Blue"
  | "Purple"
  | "Turquoise"
  | "Light Green"
  | "Orange"
  | "Light Blue";
export type StoryEntry = Entry<StoryFields>;

export function isStory(x: Entry<any>): x is StoryEntry {
  return x.sys.contentType.sys.id === "story";
}

export type TextContentModuleFields = {
  label?: EntryFields.Symbol;
  title?: EntryFields.Symbol;
  text?: EntryFields.RichText;
};
export type TextContentModuleEntry = Entry<TextContentModuleFields>;

export function isTextContentModule(
  x: Entry<any>
): x is TextContentModuleEntry {
  return x.sys.contentType.sys.id === "textContentModule";
}

export type VideoContentModuleFields = {
  label?: EntryFields.Symbol;
  video?: UnresolvedLink<"Asset">;
  posterImage?: UnresolvedLink<"Asset">;
  captions?: UnresolvedLink<"Asset">;
  description?: EntryFields.RichText;
  credit?: EntryFields.RichText;
};
export type VideoContentModuleEntry = Entry<VideoContentModuleFields>;

export function isVideoContentModule(
  x: Entry<any>
): x is VideoContentModuleEntry {
  return x.sys.contentType.sys.id === "videoContentModule";
}

export type AllEntryTypes =
  | AudioContentModuleEntry
  | ConsoleEntry
  | FunFactContentModuleEntry
  | GalleryEntry
  | ImageContentModuleEntry
  | ImageGalleryImageEntry
  | ObjectEntry
  | PollEntry
  | PollContentModuleEntry
  | QuizEntry
  | QuizContentModuleEntry
  | QuoteContentModuleEntry
  | ShowcaseEntry
  | StoryEntry
  | TextContentModuleEntry
  | VideoContentModuleEntry;

export class Collection {
  // Contentful Asset type
  assets: Map<string, Asset>;

  // Other entry types
  audioContentModules: Map<string, AudioContentModuleEntry>;
  consoles: Map<string, ConsoleEntry>;
  funFactContentModules: Map<string, FunFactContentModuleEntry>;
  galleries: Map<string, GalleryEntry>;
  imageContentModules: Map<string, ImageContentModuleEntry>;
  imageGalleryImages: Map<string, ImageGalleryImageEntry>;
  objects: Map<string, ObjectEntry>;
  polls: Map<string, PollEntry>;
  pollContentModules: Map<string, PollContentModuleEntry>;
  quizzes: Map<string, QuizEntry>;
  quizContentModules: Map<string, QuizContentModuleEntry>;
  quoteContentModules: Map<string, QuoteContentModuleEntry>;
  showcases: Map<string, ShowcaseEntry>;
  stories: Map<string, StoryEntry>;
  textContentModules: Map<string, TextContentModuleEntry>;
  videoContentModules: Map<string, VideoContentModuleEntry>;

  constructor() {
    this.assets = new Map();
    this.audioContentModules = new Map();
    this.consoles = new Map();
    this.funFactContentModules = new Map();
    this.galleries = new Map();
    this.imageContentModules = new Map();
    this.imageGalleryImages = new Map();
    this.objects = new Map();
    this.polls = new Map();
    this.pollContentModules = new Map();
    this.quizzes = new Map();
    this.quizContentModules = new Map();
    this.quoteContentModules = new Map();
    this.showcases = new Map();
    this.stories = new Map();
    this.textContentModules = new Map();
    this.videoContentModules = new Map();
  }

  getAsset(link: UnresolvedLink<"Asset">): Asset | undefined {
    return resolveLink(this.assets, link);
  }

  getAudioContentModule(
    link: UnresolvedLink<"Entry">
  ): AudioContentModuleEntry | undefined {
    return resolveLink(this.audioContentModules, link);
  }

  getConsole(link: UnresolvedLink<"Entry">): ConsoleEntry | undefined {
    return resolveLink(this.consoles, link);
  }

  getFunFactContentModule(
    link: UnresolvedLink<"Entry">
  ): FunFactContentModuleEntry | undefined {
    return resolveLink(this.funFactContentModules, link);
  }

  getGallery(link: UnresolvedLink<"Entry">): GalleryEntry | undefined {
    return resolveLink(this.galleries, link);
  }

  getImageContentModule(
    link: UnresolvedLink<"Entry">
  ): ImageContentModuleEntry | undefined {
    return resolveLink(this.imageContentModules, link);
  }

  getImageGalleryImage(
    link: UnresolvedLink<"Entry">
  ): ImageGalleryImageEntry | undefined {
    return resolveLink(this.imageGalleryImages, link);
  }

  getObject(link: UnresolvedLink<"Entry">): ObjectEntry | undefined {
    return resolveLink(this.objects, link);
  }

  getPoll(link: UnresolvedLink<"Entry">): PollEntry | undefined {
    return resolveLink(this.polls, link);
  }

  getPollContentModule(
    link: UnresolvedLink<"Entry">
  ): PollContentModuleEntry | undefined {
    return resolveLink(this.pollContentModules, link);
  }

  getQuiz(link: UnresolvedLink<"Entry">): QuizEntry | undefined {
    return resolveLink(this.quizzes, link);
  }

  getQuizContentModule(
    link: UnresolvedLink<"Entry">
  ): QuizContentModuleEntry | undefined {
    return resolveLink(this.quizContentModules, link);
  }

  getQuoteContentModule(
    link: UnresolvedLink<"Entry">
  ): QuoteContentModuleEntry | undefined {
    return resolveLink(this.quoteContentModules, link);
  }

  getShowcase(link: UnresolvedLink<"Entry">): ShowcaseEntry | undefined {
    return resolveLink(this.showcases, link);
  }

  getStory(link: UnresolvedLink<"Entry">): StoryEntry | undefined {
    return resolveLink(this.stories, link);
  }

  getTextContentModule(
    link: UnresolvedLink<"Entry">
  ): TextContentModuleEntry | undefined {
    return resolveLink(this.textContentModules, link);
  }

  getVideoContentModule(
    link: UnresolvedLink<"Entry">
  ): VideoContentModuleEntry | undefined {
    return resolveLink(this.videoContentModules, link);
  }

  getEntry(link: UnresolvedLink<"Entry">): AllEntryTypes {
    let entry: AllEntryTypes | undefined;

    entry = this.audioContentModules.get(link.sys.id);
    if (entry !== undefined) {
      return entry;
    }

    entry = this.consoles.get(link.sys.id);
    if (entry !== undefined) {
      return entry;
    }

    entry = this.funFactContentModules.get(link.sys.id);
    if (entry !== undefined) {
      return entry;
    }

    entry = this.galleries.get(link.sys.id);
    if (entry !== undefined) {
      return entry;
    }

    entry = this.imageContentModules.get(link.sys.id);
    if (entry !== undefined) {
      return entry;
    }

    entry = this.imageGalleryImages.get(link.sys.id);
    if (entry !== undefined) {
      return entry;
    }

    entry = this.objects.get(link.sys.id);
    if (entry !== undefined) {
      return entry;
    }

    entry = this.polls.get(link.sys.id);
    if (entry !== undefined) {
      return entry;
    }

    entry = this.pollContentModules.get(link.sys.id);
    if (entry !== undefined) {
      return entry;
    }

    entry = this.quizzes.get(link.sys.id);
    if (entry !== undefined) {
      return entry;
    }

    entry = this.quizContentModules.get(link.sys.id);
    if (entry !== undefined) {
      return entry;
    }

    entry = this.quoteContentModules.get(link.sys.id);
    if (entry !== undefined) {
      return entry;
    }

    entry = this.showcases.get(link.sys.id);
    if (entry !== undefined) {
      return entry;
    }

    entry = this.stories.get(link.sys.id);
    if (entry !== undefined) {
      return entry;
    }

    entry = this.textContentModules.get(link.sys.id);
    if (entry !== undefined) {
      return entry;
    }

    entry = this.videoContentModules.get(link.sys.id);
    if (entry !== undefined) {
      return entry;
    }

    throw Error("Links should always be resolvable");
  }

  static fromEntries(entries: Array<Entry<any>>): Collection {
    let collection = new Collection();

    for (let entry of entries) {
      if (isAudioContentModule(entry)) {
        collection.audioContentModules.set(entry.sys.id, entry);
      } else if (isConsole(entry)) {
        collection.consoles.set(entry.sys.id, entry);
      } else if (isFunFactContentModule(entry)) {
        collection.funFactContentModules.set(entry.sys.id, entry);
      } else if (isGallery(entry)) {
        collection.galleries.set(entry.sys.id, entry);
      } else if (isImageContentModule(entry)) {
        collection.imageContentModules.set(entry.sys.id, entry);
      } else if (isImageGalleryImage(entry)) {
        collection.imageGalleryImages.set(entry.sys.id, entry);
      } else if (isObject(entry)) {
        collection.objects.set(entry.sys.id, entry);
      } else if (isPoll(entry)) {
        collection.polls.set(entry.sys.id, entry);
      } else if (isPollContentModule(entry)) {
        collection.pollContentModules.set(entry.sys.id, entry);
      } else if (isQuiz(entry)) {
        collection.quizzes.set(entry.sys.id, entry);
      } else if (isQuizContentModule(entry)) {
        collection.quizContentModules.set(entry.sys.id, entry);
      } else if (isQuoteContentModule(entry)) {
        collection.quoteContentModules.set(entry.sys.id, entry);
      } else if (isShowcase(entry)) {
        collection.showcases.set(entry.sys.id, entry);
      } else if (isStory(entry)) {
        collection.stories.set(entry.sys.id, entry);
      } else if (isTextContentModule(entry)) {
        collection.textContentModules.set(entry.sys.id, entry);
      } else if (isVideoContentModule(entry)) {
        collection.videoContentModules.set(entry.sys.id, entry);
      } else {
        throw Error("Unknown content type");
      }
    }

    return collection;
  }

  static fromEntriesAndAssets(
    entries: Array<Entry<any>>,
    assets: Array<Asset>
  ): Collection {
    let collection = this.fromEntries(entries);

    for (let asset of assets) {
      collection.assets.set(asset.sys.id, asset);
    }

    return collection;
  }
}
