import React from "react";

import AvatarContainer from "./AvatarContainer";

export default (
  <AvatarContainer>
    <path
      fill="inherit"
      d="M48 4c24.3 0 44 19.7 44 44S72.3 92 48 92 4 72.3 4 48 23.7 4 48 4zm0 4C25.909 8 8 25.909 8 48s17.909 40 40 40 40-17.909 40-40S70.091 8 48 8zm.043 64.756c3.048 0 5.52 2.486 5.52 5.552 0 .367-.035.728-.104 1.082a24.71 24.71 0 01-10.827.02 5.476 5.476 0 01-.11-1.102c0-3.066 2.471-5.552 5.52-5.552zM20.16 51.435l-.036-.42c5.836 9.589 16.304 15.73 27.919 15.73 11.58 0 21.981-6.103 27.82-15.566-.617 7.1-3.305 14.01-8.064 19.449-3.725 4.257-8.238 7.063-13.003 8.42.027-.244.04-.49.04-.74 0-3.773-3.042-6.832-6.793-6.832-3.753 0-6.794 3.059-6.794 6.832 0 .258.014.513.042.765-4.797-1.347-9.343-4.162-13.09-8.445-4.703-5.374-7.383-12.183-8.041-19.193l-.036-.42.036.42zm1.286-13.61l.09-.299a45.674 45.674 0 0026.506 8.434c9.61 0 18.77-2.969 26.437-8.384A36.287 36.287 0 0176 48.346c-5.296 10.314-15.97 17.12-27.956 17.12-12.018 0-22.758-6.842-28.043-17.292a36.288 36.288 0 011.446-10.349l.09-.299-.09.3zm1.616 2.593l-.185-.004a.638.638 0 00-.548.529 33.781 33.781 0 00-.412 7.42c.02.352.32.623.67.603.351-.02.62-.321.601-.674-.13-2.387.002-4.786.396-7.137a.64.64 0 00-.43-.714l-.092-.023zm49.943-.009l-.094.009a.64.64 0 00-.522.738c.395 2.349.528 4.748.397 7.136-.02.353.25.655.6.674.351.02.652-.25.67-.604a33.742 33.742 0 00-.412-7.42.637.637 0 00-.64-.533zm-5.206-15.037c2.814 3.217 4.904 6.947 6.27 10.928a44.41 44.41 0 01-26.027 8.38 44.409 44.409 0 01-26.094-8.43c1.365-3.961 3.45-7.675 6.252-10.878 10.935-12.496 28.664-12.496 39.599 0z"
    />
  </AvatarContainer>
);
